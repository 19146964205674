var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-sheet",
    {
      staticClass: "display-settings",
      attrs: { width: "100%" },
      on: {
        mouseenter: _vm.disableResizeable,
        mouseleave: _vm.enableResizeable,
      },
    },
    [
      _c(
        "v-tabs",
        {
          staticClass: "settings-tabs",
          class: { " pt-5": _vm.$vuetify.breakpoint.mdAndUp },
          attrs: {
            "center-active": !_vm.$vuetify.breakpoint.mdAndUp,
            centered: !_vm.$vuetify.breakpoint.mdAndUp,
            vertical: _vm.$vuetify.breakpoint.mdAndUp,
            "show-arrows": "",
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "mt-3",
              class: {
                column: _vm.$vuetify.breakpoint.mdAndUp,
                "d-flex": !_vm.$vuetify.breakpoint.mdAndUp,
              },
              staticStyle: {
                "max-height": "calc(100vh - 84px)",
                "overflow-y": "auto",
              },
            },
            [
              _c(
                "v-tab",
                { staticClass: "justify-start" },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("fal fa-globe"),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("generalLabel")))]),
                ],
                1
              ),
              _vm._l(_vm.filteredDisplaySettingsTabs, function (item, i) {
                return _c(
                  "v-tab",
                  {
                    key: i,
                    staticClass: "justify-start",
                    style: {
                      "margin-top":
                        item.id === "printLayoutHeader" ? "-3px" : undefined,
                    },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v(_vm._s(item.icon)),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.$t(item.label)))]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c("v-tab-item", [
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _c(
                  "v-card",
                  { attrs: { flat: "", width: "100%", "max-width": "800px" } },
                  [
                    _c(
                      "perfect-scrollbar",
                      { style: { "max-height": _vm.scrollHeight } },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "px-0 py-0" },
                          [
                            _c(
                              "v-container",
                              { staticClass: "px-10" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              outlined: "",
                                              width: "100%",
                                              "max-width": "800px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "px-3 pt-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "title-on-border",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("themeLabel")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-inline-block mr-5",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              "aria-labelledby":
                                                                "lightModeLabel",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                $event.preventDefault()
                                                                _vm.darkMode = false
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm.darkMode
                                                              ? _c("v-icon", [
                                                                  _vm._v(
                                                                    "fa-thin fa-sun"
                                                                  ),
                                                                ])
                                                              : _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fas fa-sun"
                                                                    ),
                                                                  ]
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          id: "lightModeLabel",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("lightLabel")
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-inline-block",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              "aria-labelledby":
                                                                "darkModeLabel",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                $event.preventDefault()
                                                                _vm.darkMode = true
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm.darkMode
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fas fa-moon"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c("v-icon", [
                                                                  _vm._v(
                                                                    "fa-thin fa-moon"
                                                                  ),
                                                                ]),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          id: "darkModeLabel",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("darkLabel")
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  [
                                                    _c("v-switch", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: !_vm.darkMode,
                                                          expression:
                                                            "!darkMode",
                                                        },
                                                      ],
                                                      attrs: {
                                                        label:
                                                          _vm.$t(
                                                            "contrastLabel"
                                                          ),
                                                        "hide-details": "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.highContrastMode,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.highContrastMode =
                                                            $$v
                                                        },
                                                        expression:
                                                          "highContrastMode",
                                                      },
                                                    }),
                                                    _c("v-switch", {
                                                      attrs: {
                                                        label:
                                                          _vm.$t(
                                                            "automaticLabel"
                                                          ),
                                                        "hide-details": "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.autoColorScheme,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.autoColorScheme =
                                                            $$v
                                                        },
                                                        expression:
                                                          "autoColorScheme",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            label: _vm.$t("fontFamilyLabel"),
                                            outlined: "",
                                            "hide-details": "auto",
                                            items: _vm.fonts,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "selection",
                                              fn: function (data) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      style: {
                                                        fontFamily:
                                                          data.item.text,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(data.item.text)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item",
                                              fn: function (data) {
                                                return [
                                                  _c(
                                                    "v-list-item",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          style: {
                                                            fontFamily:
                                                              data.item.text,
                                                          },
                                                          attrs: {
                                                            value:
                                                              data.item.value,
                                                          },
                                                        },
                                                        "v-list-item",
                                                        data.attrs,
                                                        false
                                                      ),
                                                      data.on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        _vm._s(data.item.text)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value: _vm.globalFontType,
                                            callback: function ($$v) {
                                              _vm.globalFontType = $$v
                                            },
                                            expression: "globalFontType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            label: _vm.$t("fontSizeLabel"),
                                            outlined: "",
                                            "hide-details": "auto",
                                            items: _vm.fontSizes,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "selection",
                                              fn: function (data) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      style: {
                                                        fontSize:
                                                          data.item.text,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(data.item.text)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item",
                                              fn: function (data) {
                                                return [
                                                  _c(
                                                    "v-list-item",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          style: {
                                                            fontSize:
                                                              data.item.text,
                                                          },
                                                          attrs: {
                                                            value:
                                                              data.item.value,
                                                          },
                                                        },
                                                        "v-list-item",
                                                        data.attrs,
                                                        false
                                                      ),
                                                      data.on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        _vm._s(data.item.text)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value: _vm.globalFontSize,
                                            callback: function ($$v) {
                                              _vm.globalFontSize = $$v
                                            },
                                            expression: "globalFontSize",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              outlined: "",
                                              width: "100%",
                                              "max-width": "800px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "px-3 pt-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "title-on-border",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "plansTextScaleLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-weight": "bold",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            ` (${_vm.plansTextScale}%)`
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c("v-slider", {
                                                  attrs: {
                                                    dense: "",
                                                    "hide-details": "auto",
                                                    min: "80",
                                                    max: "180",
                                                    "aria-label": _vm.$t(
                                                      "plansTextScaleLabel"
                                                    ),
                                                  },
                                                  model: {
                                                    value: _vm.plansTextScale,
                                                    callback: function ($$v) {
                                                      _vm.plansTextScale = $$v
                                                    },
                                                    expression:
                                                      "plansTextScale",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              outlined: "",
                                              width: "100%",
                                              "max-width": "800px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "px-3 pt-6" },
                                              [
                                                _c(
                                                  "v-radio-group",
                                                  {
                                                    staticClass: "mt-0 pt-0",
                                                    attrs: {
                                                      row: "",
                                                      "hide-details": "auto",
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "radio-title-on-border",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "defaultEditorModeLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ]),
                                                    model: {
                                                      value:
                                                        _vm.defaultEditorMode,
                                                      callback: function ($$v) {
                                                        _vm.defaultEditorMode =
                                                          $$v
                                                      },
                                                      expression:
                                                        "defaultEditorMode",
                                                    },
                                                  },
                                                  [
                                                    _c("v-radio", {
                                                      staticStyle: {
                                                        "margin-left":
                                                          "-8px !important",
                                                      },
                                                      attrs: {
                                                        label:
                                                          _vm.$t("modalLabel"),
                                                        "on-icon":
                                                          "fas fa-memo-pad",
                                                        "off-icon":
                                                          "fal fa-memo-pad",
                                                        value: "modal",
                                                      },
                                                    }),
                                                    _c("v-radio", {
                                                      attrs: {
                                                        label:
                                                          _vm.$t("splitLabel"),
                                                        "on-icon":
                                                          "fas fa-table-columns",
                                                        "off-icon":
                                                          "fal fa-table-columns",
                                                        value: "split",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              outlined: "",
                                              width: "100%",
                                              "max-width": "800px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "px-3 pt-6" },
                                              [
                                                _c(
                                                  "v-radio-group",
                                                  {
                                                    staticClass: "mt-0 pt-0",
                                                    attrs: {
                                                      row: "",
                                                      "hide-details": "auto",
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "radio-title-on-border",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "leftDrawerMenuLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ]),
                                                    model: {
                                                      value:
                                                        _vm.leftDrawerMenuType,
                                                      callback: function ($$v) {
                                                        _vm.leftDrawerMenuType =
                                                          $$v
                                                      },
                                                      expression:
                                                        "leftDrawerMenuType",
                                                    },
                                                  },
                                                  [
                                                    _c("v-radio", {
                                                      staticStyle: {
                                                        "margin-left":
                                                          "-8px !important",
                                                      },
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "dockedExpandOnHoverLabel"
                                                        ),
                                                        value: 0,
                                                      },
                                                    }),
                                                    _c("v-radio", {
                                                      attrs: {
                                                        label:
                                                          _vm.$t(
                                                            "dockedCompactLabel"
                                                          ),
                                                        value: 1,
                                                      },
                                                    }),
                                                    _c("v-radio", {
                                                      staticClass: "mt-1",
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "hiddenShowOnClickLabel"
                                                        ),
                                                        value: 2,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            label: _vm.$t("languageLabel"),
                                            outlined: "",
                                            "hide-details": "auto",
                                            items: _vm.globalLanguageItems,
                                          },
                                          model: {
                                            value: _vm.globalLanguage,
                                            callback: function ($$v) {
                                              _vm.globalLanguage = $$v
                                            },
                                            expression: "globalLanguage",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.isTeacher
                                  ? _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-switch", {
                                              staticClass: "pa-0 ma-0",
                                              attrs: {
                                                inset: "",
                                                label: _vm.$t(
                                                  "showSchoolDayCounterLabel"
                                                ),
                                                "hide-details": "auto",
                                              },
                                              model: {
                                                value: _vm.showCount,
                                                callback: function ($$v) {
                                                  _vm.showCount = $$v
                                                },
                                                expression: "showCount",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-switch", {
                                              staticClass: "pa-0 ma-0",
                                              attrs: {
                                                inset: "",
                                                label: _vm.$t(
                                                  "reduceBrightnessOfPastDays"
                                                ),
                                                "hide-details": "auto",
                                              },
                                              model: {
                                                value:
                                                  _vm.reduceBrightnessOfPastDays,
                                                callback: function ($$v) {
                                                  _vm.reduceBrightnessOfPastDays =
                                                    $$v
                                                },
                                                expression:
                                                  "reduceBrightnessOfPastDays",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-switch", {
                                          staticClass: "pa-0 ma-0",
                                          attrs: {
                                            inset: "",
                                            label: _vm.$t(
                                              "showSnackbarNotificationsLabel"
                                            ),
                                            "hide-details": "auto",
                                          },
                                          model: {
                                            value:
                                              _vm.showSnackbarNotifications,
                                            callback: function ($$v) {
                                              _vm.showSnackbarNotifications =
                                                $$v
                                            },
                                            expression:
                                              "showSnackbarNotifications",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "justify-end px-10 pt-3" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              width: "175px",
                              color: "error",
                              disabled: _vm.isNotDirty,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.saveGlobalSettings.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._l(_vm.filteredDisplaySettingsTabs, function (item, i) {
            return _c("v-tab-item", { key: i }, [
              item.id === "lessonSectionsGrouping"
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            width: "100%",
                            "max-width": "800px",
                          },
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            { style: { "max-height": _vm.scrollHeight } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "px-0 py-0" },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "px-10" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("groupByLabel")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-center",
                                                },
                                                [
                                                  _c(
                                                    "v-btn-toggle",
                                                    {
                                                      attrs: {
                                                        dense: "",
                                                        color: "primary",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.lessonSectionsGroupingValue,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.lessonSectionsGroupingValue =
                                                            $$v
                                                        },
                                                        expression:
                                                          "lessonSectionsGroupingValue",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            value: 1,
                                                            width: "150px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "sectionLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            value: 2,
                                                            width: "150px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "typeLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-center",
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass: "rounded-lg",
                                                      style: {
                                                        "border-color":
                                                          "#546E7A",
                                                        "background-color":
                                                          "#546E7A",
                                                      },
                                                      attrs: {
                                                        width: "400px",
                                                        outlined: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass: "pa-1",
                                                          staticStyle: {
                                                            "background-color":
                                                              "#546E7A",
                                                            color: "white",
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "lessonLabel"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm.lessonSectionsGroupingValue ===
                                                      1
                                                        ? _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "pa-0",
                                                              staticStyle: {
                                                                "background-color":
                                                                  "white",
                                                                "border-bottom-left-radius":
                                                                  "7px",
                                                                "border-bottom-right-radius":
                                                                  "7px",
                                                              },
                                                              style: {
                                                                color: _vm
                                                                  .$vuetify
                                                                  .theme.dark
                                                                  ? "#00000099"
                                                                  : undefined,
                                                              },
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "plans-place-holder",
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "border-top":
                                                                      "1px dotted #546E7A",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-table",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-table-cell font-weight-bold",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "sectionTitleLabel"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("div", [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "draggable",
                                                                      {
                                                                        attrs: {
                                                                          forceFallback: true,
                                                                          animation: 200,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.sectionGrouping,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.sectionGrouping =
                                                                                $$v
                                                                            },
                                                                          expression:
                                                                            "sectionGrouping",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.sectionGrouping,
                                                                        function (
                                                                          item,
                                                                          i
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: i,
                                                                              staticClass:
                                                                                "ma-2 pa-2 d-table",
                                                                              staticStyle:
                                                                                {
                                                                                  border:
                                                                                    "1px dashed #546E7A",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-table-cell",
                                                                                  staticStyle:
                                                                                    {
                                                                                      width:
                                                                                        "100%",
                                                                                      "white-space":
                                                                                        "nowrap",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  item.tag
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-inline-block",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-avatar",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mr-2",
                                                                                              attrs:
                                                                                                {
                                                                                                  color:
                                                                                                    "info",
                                                                                                  size: "20",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "white--text font-weight-bold",
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "font-size":
                                                                                                        "12px",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      item.tag
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-inline-block",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item.text
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-table-cell",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-menu",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          bottom:
                                                                                            "",
                                                                                          "offset-y":
                                                                                            "",
                                                                                        },
                                                                                      scopedSlots:
                                                                                        _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key: "activator",
                                                                                              fn: function ({
                                                                                                on: onMenu,
                                                                                              }) {
                                                                                                return [
                                                                                                  _c(
                                                                                                    "v-btn",
                                                                                                    _vm._g(
                                                                                                      {
                                                                                                        ref:
                                                                                                          "sectionButton" +
                                                                                                          i,
                                                                                                        refInFor: true,
                                                                                                        attrs:
                                                                                                          {
                                                                                                            fab: "",
                                                                                                            "x-small":
                                                                                                              "",
                                                                                                            elevation:
                                                                                                              "0",
                                                                                                            "aria-label":
                                                                                                              _vm.$t(
                                                                                                                "dragReorderItemLabel"
                                                                                                              ),
                                                                                                          },
                                                                                                      },
                                                                                                      onMenu
                                                                                                    ),
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "fal fa-sort-alt"
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ]
                                                                                              },
                                                                                            },
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        ),
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-list",
                                                                                        [
                                                                                          i !==
                                                                                          0
                                                                                            ? _c(
                                                                                                "v-list-item",
                                                                                                {
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.moveSectionUp(
                                                                                                          i
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "moveUpLabel"
                                                                                                      )
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          i !==
                                                                                          _vm
                                                                                            .sectionGrouping
                                                                                            .length -
                                                                                            1
                                                                                            ? _c(
                                                                                                "v-list-item",
                                                                                                {
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.moveSectionDown(
                                                                                                          i
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "moveDownLabel"
                                                                                                      )
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]),
                                                              _c("div", {
                                                                staticClass:
                                                                  "plans-place-holder",
                                                                staticStyle: {
                                                                  "border-top":
                                                                    "1px dotted #546E7A",
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "pa-0",
                                                              staticStyle: {
                                                                "background-color":
                                                                  "white",
                                                                "border-bottom-left-radius":
                                                                  "7px",
                                                                "border-bottom-right-radius":
                                                                  "7px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "draggable",
                                                                {
                                                                  staticClass:
                                                                    "pa-1",
                                                                  attrs: {
                                                                    forceFallback: true,
                                                                    animation: 200,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.typeGrouping,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.typeGrouping =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "typeGrouping",
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  _vm.typeGrouping,
                                                                  function (
                                                                    item,
                                                                    i
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: i,
                                                                        staticClass:
                                                                          "my-2 mx-1",
                                                                        staticStyle:
                                                                          {
                                                                            border:
                                                                              "1px dashed #546E7A",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "text-center ma-3",
                                                                            staticStyle:
                                                                              {
                                                                                border:
                                                                                  "1px solid #546E7A",
                                                                                "border-top":
                                                                                  "0px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "100%",
                                                                                    "text-align":
                                                                                      "center",
                                                                                    "border-bottom":
                                                                                      "1px solid #546E7A",
                                                                                    "line-height":
                                                                                      "0em",
                                                                                    margin:
                                                                                      "20px 0 10px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "font-weight-bold",
                                                                                    staticStyle:
                                                                                      {
                                                                                        background:
                                                                                          "#fff",
                                                                                        padding:
                                                                                          "0 5px",
                                                                                        color:
                                                                                          "#546E7A",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        item.text
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "d-table pa-2",
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-top":
                                                                                      "-10px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-table-cell",
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "100%",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "plans-place-holder",
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-table-cell",
                                                                                    staticStyle:
                                                                                      {
                                                                                        "vertical-align":
                                                                                          "middle",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-menu",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            bottom:
                                                                                              "",
                                                                                            "offset-y":
                                                                                              "",
                                                                                          },
                                                                                        scopedSlots:
                                                                                          _vm._u(
                                                                                            [
                                                                                              {
                                                                                                key: "activator",
                                                                                                fn: function ({
                                                                                                  on: onMenu,
                                                                                                }) {
                                                                                                  return [
                                                                                                    _c(
                                                                                                      "v-btn",
                                                                                                      _vm._g(
                                                                                                        {
                                                                                                          ref:
                                                                                                            "typeButton" +
                                                                                                            i,
                                                                                                          refInFor: true,
                                                                                                          attrs:
                                                                                                            {
                                                                                                              fab: "",
                                                                                                              "x-small":
                                                                                                                "",
                                                                                                              elevation:
                                                                                                                "0",
                                                                                                              "aria-label":
                                                                                                                _vm.$t(
                                                                                                                  "dragReorderItemLabel"
                                                                                                                ),
                                                                                                            },
                                                                                                        },
                                                                                                        onMenu
                                                                                                      ),
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "fal fa-sort-alt"
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ]
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                            null,
                                                                                            true
                                                                                          ),
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-list",
                                                                                          [
                                                                                            i !==
                                                                                            0
                                                                                              ? _c(
                                                                                                  "v-list-item",
                                                                                                  {
                                                                                                    on: {
                                                                                                      click:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          return _vm.moveTypeUp(
                                                                                                            i
                                                                                                          )
                                                                                                        },
                                                                                                    },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "moveUpLabel"
                                                                                                        )
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              : _vm._e(),
                                                                                            i !==
                                                                                            _vm
                                                                                              .typeGrouping
                                                                                              .length -
                                                                                              1
                                                                                              ? _c(
                                                                                                  "v-list-item",
                                                                                                  {
                                                                                                    on: {
                                                                                                      click:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          return _vm.moveTypeDown(
                                                                                                            i
                                                                                                          )
                                                                                                        },
                                                                                                    },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "moveDownLabel"
                                                                                                        )
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              : _vm._e(),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end px-10 pt-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "175px",
                                    color: "error",
                                    disabled: _vm.isNotDirty,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.saveSettings.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.id === "lessonAutoSave"
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            width: "100%",
                            "max-width": "800px",
                          },
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            { style: { "max-height": _vm.scrollHeight } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "px-0 py-0" },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "px-10" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  "hide-details": "auto",
                                                  label: _vm.$t(
                                                    "autoSaveIntervalLabel"
                                                  ),
                                                  items: _vm.autoSaveIntervals,
                                                },
                                                model: {
                                                  value: _vm.autosaveInterval,
                                                  callback: function ($$v) {
                                                    _vm.autosaveInterval = $$v
                                                  },
                                                  expression:
                                                    "autosaveInterval",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      false
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      "hide-details": "auto",
                                                      label: _vm.$t(
                                                        "retrieveIntervalLabel"
                                                      ),
                                                      items:
                                                        _vm.retrieveIntervals,
                                                    },
                                                    model: {
                                                      value: _vm.fetchWeekSize,
                                                      callback: function ($$v) {
                                                        _vm.fetchWeekSize = $$v
                                                      },
                                                      expression:
                                                        "fetchWeekSize",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end px-10 pt-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "175px",
                                    color: "error",
                                    disabled: _vm.isNotDirty,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.saveSettings.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.id === "lessonOrientation"
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            width: "100%",
                            "max-width": "800px",
                          },
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            { style: { "max-height": _vm.scrollHeight } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "px-0 py-0" },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "px-10" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  label:
                                                    _vm.$t("orientationLabel"),
                                                  "hide-details": "auto",
                                                  outlined: "",
                                                  items: _vm.orientationTypes,
                                                },
                                                model: {
                                                  value: _vm.viewOrientation,
                                                  callback: function ($$v) {
                                                    _vm.viewOrientation = $$v
                                                  },
                                                  expression: "viewOrientation",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.viewOrientation === 0
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("v-switch", {
                                                    staticClass: "pa-0 ma-0",
                                                    attrs: {
                                                      inset: "",
                                                      label: _vm.$t(
                                                        "equalSizeLessonsLabel"
                                                      ),
                                                      "hide-details": "auto",
                                                    },
                                                    model: {
                                                      value: _vm.equalSize,
                                                      callback: function ($$v) {
                                                        _vm.equalSize = $$v
                                                      },
                                                      expression: "equalSize",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.viewOrientation === 0
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("v-switch", {
                                                    staticClass: "pa-0 ma-0",
                                                    attrs: {
                                                      inset: "",
                                                      label:
                                                        _vm.$t(
                                                          "adjustLessonToFit"
                                                        ),
                                                      "hide-details": "auto",
                                                    },
                                                    model: {
                                                      value: _vm.adjustToFit,
                                                      callback: function ($$v) {
                                                        _vm.adjustToFit = $$v
                                                      },
                                                      expression: "adjustToFit",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.viewOrientation === 0
                                            ? _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: _vm.$vuetify
                                                      .breakpoint.mdAndUp
                                                      ? 4
                                                      : 12,
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    class: {
                                                      "ml-7":
                                                        _vm.$vuetify.breakpoint
                                                          .mdAndUp,
                                                    },
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      disabled: _vm.adjustToFit,
                                                      label:
                                                        _vm.$t("heightLabel"),
                                                      outlined: "",
                                                    },
                                                    model: {
                                                      value: _vm.boxSize,
                                                      callback: function ($$v) {
                                                        _vm.boxSize = $$v
                                                      },
                                                      expression: "boxSize",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      label: _vm.$t(
                                                        "numberOfColumnsToDisplayLabel"
                                                      ),
                                                      items: [3, 4, 5, 6, 7, 8],
                                                      outlined: "",
                                                    },
                                                    model: {
                                                      value: _vm.boxSize,
                                                      callback: function ($$v) {
                                                        _vm.boxSize = $$v
                                                      },
                                                      expression: "boxSize",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end px-10 pt-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "175px",
                                    color: "error",
                                    disabled: _vm.isNotDirty,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.saveSettings.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.id === "printLayoutHeader"
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            width: "100%",
                            "max-width": "800px",
                          },
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            { style: { "max-height": _vm.scrollHeight } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "px-0 py-0" },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "px-10" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex justify-center",
                                              attrs: {
                                                cols: "12",
                                                md: "4",
                                                "offset-md": "4",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    width: "160px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    { staticClass: "pt-0" },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "title-on-border",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "logoLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      staticStyle: {
                                                        height: "140px",
                                                      },
                                                    },
                                                    [
                                                      _vm.hasLogo
                                                        ? _c("img", {
                                                            staticStyle: {
                                                              width: "135px",
                                                              height: "135px",
                                                              "object-fit":
                                                                "cover",
                                                            },
                                                            attrs: {
                                                              src: _vm.center,
                                                              role: "presentation",
                                                            },
                                                          })
                                                        : _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                size: "120px",
                                                                color: "grey",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fas fa-user"
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-card-actions",
                                                    { staticClass: "py-0" },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            small: "",
                                                            text: "",
                                                            loading:
                                                              _vm.selectingLogo,
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.selectLogo,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "uploadLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "error",
                                                            small: "",
                                                            text: "",
                                                            width: "70px",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.removeLogo,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "resetLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _vm.topLeftCustom
                                                ? _c("v-text-field", {
                                                    attrs: {
                                                      outlined: "",
                                                      "hide-details": "auto",
                                                      label:
                                                        _vm.$t("topLeftLabel"),
                                                    },
                                                    model: {
                                                      value: _vm.topLeftPrint,
                                                      callback: function ($$v) {
                                                        _vm.topLeftPrint = $$v
                                                      },
                                                      expression:
                                                        "topLeftPrint",
                                                    },
                                                  })
                                                : _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      "hide-details": "auto",
                                                      label:
                                                        _vm.$t("topLeftLabel"),
                                                      items:
                                                        _vm.printLayoutItems,
                                                    },
                                                    model: {
                                                      value: _vm.topLeftPrint,
                                                      callback: function ($$v) {
                                                        _vm.topLeftPrint = $$v
                                                      },
                                                      expression:
                                                        "topLeftPrint",
                                                    },
                                                  }),
                                              _c("v-switch", {
                                                staticClass: "pt-0 mt-1",
                                                attrs: {
                                                  dense: "",
                                                  inset: "",
                                                  "hide-details": "auto",
                                                  label: _vm.$t("customLabel"),
                                                  "aria-label":
                                                    _vm.$t("customLabel") +
                                                    " " +
                                                    _vm.$t("topLeftLabel"),
                                                },
                                                model: {
                                                  value: _vm.topLeftCustom,
                                                  callback: function ($$v) {
                                                    _vm.topLeftCustom = $$v
                                                  },
                                                  expression: "topLeftCustom",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _vm.topRightCustom
                                                ? _c("v-text-field", {
                                                    attrs: {
                                                      outlined: "",
                                                      "hide-details": "auto",
                                                      label:
                                                        _vm.$t("topRightLabel"),
                                                    },
                                                    model: {
                                                      value: _vm.topRightPrint,
                                                      callback: function ($$v) {
                                                        _vm.topRightPrint = $$v
                                                      },
                                                      expression:
                                                        "topRightPrint",
                                                    },
                                                  })
                                                : _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      "hide-details": "auto",
                                                      label:
                                                        _vm.$t("topRightLabel"),
                                                      items:
                                                        _vm.printLayoutItems,
                                                    },
                                                    model: {
                                                      value: _vm.topRightPrint,
                                                      callback: function ($$v) {
                                                        _vm.topRightPrint = $$v
                                                      },
                                                      expression:
                                                        "topRightPrint",
                                                    },
                                                  }),
                                              _c("v-switch", {
                                                staticClass: "pt-0 mt-1",
                                                attrs: {
                                                  dense: "",
                                                  inset: "",
                                                  "hide-details": "auto",
                                                  label: _vm.$t("customLabel"),
                                                  "aria-label":
                                                    _vm.$t("customLabel") +
                                                    " " +
                                                    _vm.$t("topRightLabel"),
                                                },
                                                model: {
                                                  value: _vm.topRightCustom,
                                                  callback: function ($$v) {
                                                    _vm.topRightCustom = $$v
                                                  },
                                                  expression: "topRightCustom",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _vm.topLeftCustom2
                                                ? _c("v-text-field", {
                                                    attrs: {
                                                      outlined: "",
                                                      "hide-details": "auto",
                                                      label:
                                                        _vm.$t("topLeft2Label"),
                                                    },
                                                    model: {
                                                      value: _vm.topLeftPrint2,
                                                      callback: function ($$v) {
                                                        _vm.topLeftPrint2 = $$v
                                                      },
                                                      expression:
                                                        "topLeftPrint2",
                                                    },
                                                  })
                                                : _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      "hide-details": "auto",
                                                      label:
                                                        _vm.$t("topLeft2Label"),
                                                      items:
                                                        _vm.printLayoutItems,
                                                    },
                                                    model: {
                                                      value: _vm.topLeftPrint2,
                                                      callback: function ($$v) {
                                                        _vm.topLeftPrint2 = $$v
                                                      },
                                                      expression:
                                                        "topLeftPrint2",
                                                    },
                                                  }),
                                              _c("v-switch", {
                                                staticClass: "pt-0 mt-1",
                                                attrs: {
                                                  dense: "",
                                                  inset: "",
                                                  "hide-details": "auto",
                                                  label: _vm.$t("customLabel"),
                                                  "aria-label":
                                                    _vm.$t("customLabel") +
                                                    " " +
                                                    _vm.$t("topLeft2Label"),
                                                },
                                                model: {
                                                  value: _vm.topLeftCustom2,
                                                  callback: function ($$v) {
                                                    _vm.topLeftCustom2 = $$v
                                                  },
                                                  expression: "topLeftCustom2",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _vm.topRightCustom2
                                                ? _c("v-text-field", {
                                                    attrs: {
                                                      outlined: "",
                                                      "hide-details": "auto",
                                                      label:
                                                        _vm.$t(
                                                          "topRight2Label"
                                                        ),
                                                    },
                                                    model: {
                                                      value: _vm.topRightPrint2,
                                                      callback: function ($$v) {
                                                        _vm.topRightPrint2 = $$v
                                                      },
                                                      expression:
                                                        "topRightPrint2",
                                                    },
                                                  })
                                                : _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      "hide-details": "auto",
                                                      label:
                                                        _vm.$t(
                                                          "topRight2Label"
                                                        ),
                                                      items:
                                                        _vm.printLayoutItems,
                                                    },
                                                    model: {
                                                      value: _vm.topRightPrint2,
                                                      callback: function ($$v) {
                                                        _vm.topRightPrint2 = $$v
                                                      },
                                                      expression:
                                                        "topRightPrint2",
                                                    },
                                                  }),
                                              _c("v-switch", {
                                                staticClass: "pt-0 mt-1",
                                                attrs: {
                                                  dense: "",
                                                  inset: "",
                                                  "hide-details": "auto",
                                                  label: _vm.$t("customLabel"),
                                                  "aria-label":
                                                    _vm.$t("customLabel") +
                                                    " " +
                                                    _vm.$t("topRight2Label"),
                                                },
                                                model: {
                                                  value: _vm.topRightCustom2,
                                                  callback: function ($$v) {
                                                    _vm.topRightCustom2 = $$v
                                                  },
                                                  expression: "topRightCustom2",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end px-10 pt-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "175px",
                                    color: "error",
                                    disabled: _vm.isNotDirty,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.savePrintLayouts.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.id === "lessonHeadings"
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            width: "100%",
                            "max-width": "800px",
                          },
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            { style: { "max-height": _vm.scrollHeight } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "px-0 py-0" },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "px-10" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  "hide-details": "auto",
                                                  label: _vm.$t("orderLabel"),
                                                  items:
                                                    _vm.lessonHeadingsOrder,
                                                },
                                                model: {
                                                  value: _vm.classHeadOrder,
                                                  callback: function ($$v) {
                                                    _vm.classHeadOrder = $$v
                                                  },
                                                  expression: "classHeadOrder",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  "hide-details": "auto",
                                                  label:
                                                    _vm.$t("alignmentLabel"),
                                                  items:
                                                    _vm.lessonHeadingsAlignment,
                                                },
                                                model: {
                                                  value: _vm.classHeadCentered,
                                                  callback: function ($$v) {
                                                    _vm.classHeadCentered = $$v
                                                  },
                                                  expression:
                                                    "classHeadCentered",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end px-10 pt-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "175px",
                                    color: "error",
                                    disabled: _vm.isNotDirty,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.saveSettings.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.id === "lessonStatus"
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            width: "100%",
                            "max-width": "800px",
                          },
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            { style: { "max-height": _vm.scrollHeight } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "px-0 py-0" },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "px-10" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-switch", {
                                                staticClass: "mt-0 pt-0",
                                                attrs: {
                                                  "hide-details": "auto",
                                                  label: _vm.$t(
                                                    "displayStatusNameLabel"
                                                  ),
                                                  inset: "",
                                                },
                                                model: {
                                                  value: _vm.displayStatusText,
                                                  callback: function ($$v) {
                                                    _vm.displayStatusText = $$v
                                                  },
                                                  expression:
                                                    "displayStatusText",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  label:
                                                    _vm.$t("fontSizeLabel"),
                                                  outlined: "",
                                                  "hide-details": "auto",
                                                  items: _vm.fontSizes,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "selection",
                                                      fn: function (data) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              style: {
                                                                fontSize:
                                                                  data.item
                                                                    .text,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  data.item.text
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item",
                                                      fn: function (data) {
                                                        return [
                                                          _c(
                                                            "v-list-item",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  style: {
                                                                    fontSize:
                                                                      data.item
                                                                        .text,
                                                                  },
                                                                  attrs: {
                                                                    value:
                                                                      data.item
                                                                        .value,
                                                                  },
                                                                },
                                                                "v-list-item",
                                                                data.attrs,
                                                                false
                                                              ),
                                                              data.on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  data.item.text
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.statusTextFontSize,
                                                  callback: function ($$v) {
                                                    _vm.statusTextFontSize = $$v
                                                  },
                                                  expression:
                                                    "statusTextFontSize",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end px-10 pt-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "175px",
                                    color: "error",
                                    disabled: _vm.isNotDirty,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.saveSettings.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.id === "lessonHover"
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            width: "100%",
                            "max-width": "800px",
                          },
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            { style: { "max-height": _vm.scrollHeight } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "px-0 py-0" },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "px-10" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-switch", {
                                                staticClass: "mt-0 pt-0",
                                                attrs: {
                                                  "hide-details": "auto",
                                                  label: _vm.$t(
                                                    "standardsTooltipLabel"
                                                  ),
                                                  inset: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.displayToolTipStandardDetails,
                                                  callback: function ($$v) {
                                                    _vm.displayToolTipStandardDetails =
                                                      $$v
                                                  },
                                                  expression:
                                                    "displayToolTipStandardDetails",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-switch", {
                                                staticClass: "mt-1 pt-0",
                                                attrs: {
                                                  "hide-details": "auto",
                                                  label:
                                                    _vm.$t("sectionTagsLabel"),
                                                  inset: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.displaySesctionTags,
                                                  callback: function ($$v) {
                                                    _vm.displaySesctionTags =
                                                      $$v
                                                  },
                                                  expression:
                                                    "displaySesctionTags",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end px-10 pt-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "175px",
                                    color: "error",
                                    disabled: _vm.isNotDirty,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.saveSettings.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.id === "events"
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            width: "100%",
                            "max-width": "800px",
                          },
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            { style: { "max-height": _vm.scrollHeight } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "px-0 py-0" },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "px-10" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-center",
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass: "rounded-lg",
                                                      style:
                                                        _vm.eventsLabelStyle,
                                                      attrs: {
                                                        width: "400px",
                                                        outlined: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass: "pa-1",
                                                          style:
                                                            _vm.eventsLabelStyle,
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "eventLabel"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c("v-spacer"),
                                                          _c(
                                                            "display-format-options",
                                                            {
                                                              attrs: {
                                                                withShowTitle: false,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        attrs,
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "text-decoration":
                                                                                        "none",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      icon: "",
                                                                                      small:
                                                                                        "",
                                                                                      color:
                                                                                        _vm
                                                                                          .eventsLabelStyle
                                                                                          .color,
                                                                                      "aria-label":
                                                                                        _vm.$t(
                                                                                          "editLabel"
                                                                                        ),
                                                                                    },
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "font-size":
                                                                                        "16px",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-pencil"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                              model: {
                                                                value:
                                                                  _vm.eventsStyle,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.eventsStyle =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "eventsStyle",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-text",
                                                        {
                                                          staticClass: "pa-1",
                                                          staticStyle: {
                                                            "border-bottom-left-radius":
                                                              "7px",
                                                            "border-bottom-right-radius":
                                                              "7px",
                                                          },
                                                          style:
                                                            _vm.eventsTextStyle,
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "eventTextLabel"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c("div", [
                                                            _vm._v(
                                                              "....................."
                                                            ),
                                                          ]),
                                                          _c("div", [
                                                            _vm._v("......."),
                                                          ]),
                                                          _c("div", [
                                                            _vm._v(
                                                              "................."
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-center",
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass: "rounded-lg",
                                                      style:
                                                        _vm.eventsStickerLabelStyle,
                                                      attrs: {
                                                        width: "400px",
                                                        elevation: "4",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass: "pa-1",
                                                          style:
                                                            _vm.eventsStickerLabelStyle,
                                                        },
                                                        [
                                                          _c(
                                                            "event-sticker-image",
                                                            {
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "10px",
                                                              },
                                                              attrs: {
                                                                stickerURL:
                                                                  "https://cdn.planbook.com/images/planbook-stickers/sticker-01.png",
                                                                stickerBackgroundStyle:
                                                                  _vm.stickerBackgroundStyle,
                                                                stickerSize:
                                                                  _vm.stickerSize,
                                                                stickerBackgroundColor:
                                                                  _vm.stickerBackgroundColor,
                                                              },
                                                            }
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "stickerLabel"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c("v-spacer"),
                                                          _c(
                                                            "display-format-options",
                                                            {
                                                              attrs: {
                                                                withShowTitle: false,
                                                                showStickerFormatOption: true,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        attrs,
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "text-decoration":
                                                                                        "none",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      icon: "",
                                                                                      small:
                                                                                        "",
                                                                                      color:
                                                                                        _vm
                                                                                          .eventsStickerLabelStyle
                                                                                          .color,
                                                                                      "aria-label":
                                                                                        _vm.$t(
                                                                                          "editLabel"
                                                                                        ),
                                                                                    },
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "font-size":
                                                                                        "16px",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-pencil"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                              model: {
                                                                value:
                                                                  _vm.eventsStickerStyle,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.eventsStickerStyle =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "eventsStickerStyle",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-text",
                                                        {
                                                          staticClass: "pa-1",
                                                          staticStyle: {
                                                            "border-bottom-left-radius":
                                                              "7px",
                                                            "border-bottom-right-radius":
                                                              "7px",
                                                          },
                                                          style:
                                                            _vm.eventsStickerTextStyle,
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "stickerTextLabel"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c("div", [
                                                            _vm._v(
                                                              "....................."
                                                            ),
                                                          ]),
                                                          _c("div", [
                                                            _vm._v("......."),
                                                          ]),
                                                          _c("div", [
                                                            _vm._v(
                                                              "................."
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-switch", {
                                                staticClass: "pa-0 ma-0",
                                                attrs: {
                                                  inset: "",
                                                  label: _vm.$t(
                                                    "showAllHolidaysLabel"
                                                  ),
                                                  "hide-details": "auto",
                                                },
                                                model: {
                                                  value: _vm.showAllHolidays,
                                                  callback: function ($$v) {
                                                    _vm.showAllHolidays = $$v
                                                  },
                                                  expression: "showAllHolidays",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-switch", {
                                                staticClass: "pa-0 ma-0",
                                                attrs: {
                                                  inset: "",
                                                  label: _vm.$t(
                                                    "showAllStudentBirthdaysLabel"
                                                  ),
                                                  "hide-details": "auto",
                                                },
                                                model: {
                                                  value:
                                                    _vm.showAllStudentBirthdays,
                                                  callback: function ($$v) {
                                                    _vm.showAllStudentBirthdays =
                                                      $$v
                                                  },
                                                  expression:
                                                    "showAllStudentBirthdays",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end px-10 pt-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "175px",
                                    color: "error",
                                    disabled: _vm.isNotDirty,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.saveSettings.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.id === "dates"
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            width: "100%",
                            "max-width": "800px",
                          },
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            { style: { "max-height": _vm.scrollHeight } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "px-0 py-0" },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "px-10" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  label:
                                                    _vm.$t("dateFormatLabel"),
                                                  "hide-details": "auto",
                                                  items: _vm.dateFormatItems,
                                                },
                                                model: {
                                                  value: _vm.dateFormat,
                                                  callback: function ($$v) {
                                                    _vm.dateFormat = $$v
                                                  },
                                                  expression: "dateFormat",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  label:
                                                    _vm.$t("dateDelimiter"),
                                                  "hide-details": "auto",
                                                  items: _vm.dateDelimiterItems,
                                                },
                                                model: {
                                                  value: _vm.dateDelimiter,
                                                  callback: function ($$v) {
                                                    _vm.dateDelimiter = $$v
                                                  },
                                                  expression: "dateDelimiter",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass: "mt-2",
                                                  attrs: {
                                                    outlined: "",
                                                    width: "100%",
                                                    "max-width": "800px",
                                                  },
                                                },
                                                [
                                                  _c("v-card-text", [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "v-radio-group",
                                                          {
                                                            staticClass:
                                                              "mt-0 pt-0",
                                                            attrs: {
                                                              row: "",
                                                              "hide-details":
                                                                "auto",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "label",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "radio-title-on-border",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "timeFormatLabel"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm.timeFormat,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.timeFormat =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "timeFormat",
                                                            },
                                                          },
                                                          [
                                                            _c("v-radio", {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  "timeFormat12HourLabel"
                                                                ),
                                                                value: 0,
                                                              },
                                                            }),
                                                            _c("v-radio", {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  "timeFormat24HourLabel"
                                                                ),
                                                                value: 1,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass: "mt-2",
                                                  attrs: {
                                                    outlined: "",
                                                    width: "100%",
                                                    "max-width": "800px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    {
                                                      staticClass: "px-3 pt-6",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "title-on-border",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "currentDateHightlightColorsLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-inline-block mr-5",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "16px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "fillLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "pb-color-picker",
                                                              {
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "pl-0",
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "",
                                                                                        "x-large":
                                                                                          "",
                                                                                        color:
                                                                                          _vm.currentDateBackgroundColor,
                                                                                        "aria-label":
                                                                                          _vm.$t(
                                                                                            "pickItemColorLabel",
                                                                                            {
                                                                                              item: _vm.$t(
                                                                                                "fillLabel"
                                                                                              ),
                                                                                            }
                                                                                          ),
                                                                                      },
                                                                                  },
                                                                                  "v-btn",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fas fa-square"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm.currentDateBackgroundColor,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.currentDateBackgroundColor =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "currentDateBackgroundColor",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-inline-block",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "16px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "textLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "pb-color-picker",
                                                              {
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "pl-0",
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "",
                                                                                        "x-large":
                                                                                          "",
                                                                                        color:
                                                                                          _vm.currentDateTextColor,
                                                                                        "aria-label":
                                                                                          _vm.$t(
                                                                                            "pickItemColorLabel",
                                                                                            {
                                                                                              item: _vm.$t(
                                                                                                "textLabel"
                                                                                              ),
                                                                                            }
                                                                                          ),
                                                                                      },
                                                                                  },
                                                                                  "v-btn",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fas fa-square"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm.currentDateTextColor,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.currentDateTextColor =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "currentDateTextColor",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end px-10 pt-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "175px",
                                    color: "error",
                                    disabled: _vm.isNotDirty,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.saveSettings.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.id === "classwork"
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            width: "100%",
                            "max-width": "800px",
                          },
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            { style: { "max-height": _vm.scrollHeight } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "px-0 py-0" },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "px-10" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass: "mt-2",
                                                  attrs: {
                                                    outlined: "",
                                                    width: "100%",
                                                    "max-width": "800px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    {
                                                      staticClass: "px-3 pt-6",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "title-on-border",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "classworkColorLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-inline-block mr-5",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "16px",
                                                                },
                                                                attrs: {
                                                                  id: "assessmentColorLabel",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "assessmentsLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "pb-color-picker",
                                                              {
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "pl-0",
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "",
                                                                                        "x-large":
                                                                                          "",
                                                                                        color:
                                                                                          _vm.currentAssessmentColor,
                                                                                        "aria-labelledby":
                                                                                          "assessmentColorLabel",
                                                                                      },
                                                                                  },
                                                                                  "v-btn",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fa-regular fa-clipboard-list-check"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm.currentAssessmentColor,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.currentAssessmentColor =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "currentAssessmentColor",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-inline-block",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "16px",
                                                                },
                                                                attrs: {
                                                                  id: "assignmentColorLabel",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "assignmentsLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "pb-color-picker",
                                                              {
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "pl-0",
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "",
                                                                                        "x-large":
                                                                                          "",
                                                                                        color:
                                                                                          _vm.currentAssignmentColor,
                                                                                        "aria-labelledby":
                                                                                          "assignmentColorLabel",
                                                                                      },
                                                                                  },
                                                                                  "v-btn",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fa-regular fa-calendar-lines-pen"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm.currentAssignmentColor,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.currentAssignmentColor =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "currentAssignmentColor",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end px-10 pt-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "175px",
                                    color: "error",
                                    disabled: _vm.isNotDirty,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.saveSettings.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.id === "customFonts"
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            width: "100%",
                            "max-width": "800px",
                          },
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            { style: { "max-height": _vm.scrollHeight } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "px-0 py-0" },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "px-10" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "mt-2 pa-0",
                                              staticStyle: {
                                                border: "1px solid #0000001f",
                                                "border-radius": "8px",
                                              },
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ma-5 mb-2",
                                                  attrs: {
                                                    small: "",
                                                    fab: "",
                                                    elevation: "0",
                                                    "aria-label": _vm.$t(
                                                      "addItemLabel",
                                                      {
                                                        text: _vm.$t(
                                                          "fontLabel"
                                                        ),
                                                      }
                                                    ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.openCustomFontDialog.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("fal fa-plus"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("v-data-table", {
                                                staticStyle: {
                                                  "border-radius": "7px",
                                                },
                                                attrs: {
                                                  "hide-default-footer": "",
                                                  "disable-pagination": "",
                                                  "disable-filtering": "",
                                                  headers:
                                                    _vm.customFontHeaders,
                                                  items: _vm.customFonts,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "item",
                                                      fn: function ({
                                                        item,
                                                        index,
                                                      }) {
                                                        return [
                                                          _c("tr", [
                                                            _c(
                                                              "td",
                                                              {
                                                                style: {
                                                                  fontFamily:
                                                                    _vm.getFontFamilyKey(
                                                                      item
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.fontFamily
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      "open-delay":
                                                                        "500",
                                                                      bottom:
                                                                        "",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                              attrs,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "max-width":
                                                                                              "400px",
                                                                                            "white-space":
                                                                                              "nowrap",
                                                                                            "overflow-x":
                                                                                              "hidden",
                                                                                            "text-overflow":
                                                                                              "ellipsis",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            "aria-hidden":
                                                                                              "true",
                                                                                          },
                                                                                      },
                                                                                      "div",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        item.fontURL
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.fontURL
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "td",
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      "aria-label":
                                                                        _vm.$t(
                                                                          "removeItemLabel",
                                                                          {
                                                                            text: _vm.$t(
                                                                              "fontLabel"
                                                                            ),
                                                                          }
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.removeFont(
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "fal fa-trash-alt"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end px-10 pt-5" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "175px",
                                    color: "error",
                                    disabled: _vm.isNotDirty,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.saveCustomFonts.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.id === "studentDisplay"
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            width: "100%",
                            "max-width": "800px",
                          },
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            { style: { "max-height": _vm.scrollHeight } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "px-0 py-0" },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "px-10" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  label:
                                                    _vm.$t("nameOrderLabel"),
                                                  "hide-details": "auto",
                                                  items: _vm.nameOrderItems,
                                                },
                                                model: {
                                                  value: _vm.nameOrder,
                                                  callback: function ($$v) {
                                                    _vm.nameOrder = $$v
                                                  },
                                                  expression: "nameOrder",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-switch", {
                                                staticClass: "pa-0 ma-0",
                                                attrs: {
                                                  inset: "",
                                                  label: _vm.$t(
                                                    "separateNameWithCommaLabel"
                                                  ),
                                                  "hide-details": "auto",
                                                  disabled:
                                                    _vm.nameCommaSeparatedDisabled,
                                                },
                                                model: {
                                                  value:
                                                    _vm.isNameCommaSeparated,
                                                  callback: function ($$v) {
                                                    _vm.isNameCommaSeparated =
                                                      $$v
                                                  },
                                                  expression:
                                                    "isNameCommaSeparated",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end px-10 pt-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "175px",
                                    color: "error",
                                    disabled: _vm.isNotDirty,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.saveSettings.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.id === "notesTodo"
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            width: "100%",
                            "max-width": "800px",
                          },
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            { style: { "max-height": _vm.scrollHeight } },
                            [
                              _c(
                                "v-card-text",
                                {
                                  staticClass: "px-0 py-0",
                                  staticStyle: {
                                    "max-height": "calc(100vh - 180px)",
                                    "overflow-y": "auto",
                                  },
                                },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "px-10" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass: "mt-2",
                                                  attrs: {
                                                    outlined: "",
                                                    width: "100%",
                                                    "max-width": "800px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    {
                                                      staticClass: "px-3 pt-6",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "title-on-border",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "iconsColorLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-inline-block mr-5",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "16px",
                                                                },
                                                                attrs: {
                                                                  id: "noteColorLabel",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "notesLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "pb-color-picker",
                                                              {
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "pl-0",
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "",
                                                                                        "x-large":
                                                                                          "",
                                                                                        color:
                                                                                          _vm.notesIconColor,
                                                                                        "aria-labelledby":
                                                                                          "noteColorLabel",
                                                                                      },
                                                                                  },
                                                                                  "v-btn",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    class:
                                                                                      {
                                                                                        outlined:
                                                                                          _vm.notesIconColor ===
                                                                                          "#FFFFFF",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fas fa-note"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm.notesIconColor,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.notesIconColor =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "notesIconColor",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-inline-block",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "16px",
                                                                },
                                                                attrs: {
                                                                  id: "todoColorLabel",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "todoEvent"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "pb-color-picker",
                                                              {
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "pl-0",
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "",
                                                                                        "x-large":
                                                                                          "",
                                                                                        color:
                                                                                          _vm.toDosIconColor,
                                                                                        "aria-labelledby":
                                                                                          "todoColorLabel",
                                                                                      },
                                                                                  },
                                                                                  "v-btn",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    class:
                                                                                      {
                                                                                        outlined:
                                                                                          _vm.toDosIconColor ===
                                                                                          "#FFFFFF",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fa-regular fa-clipboard-check"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm.toDosIconColor,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.toDosIconColor =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "toDosIconColor",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass: "mt-2",
                                                  attrs: {
                                                    outlined: "",
                                                    width: "100%",
                                                    "max-width": "800px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    {
                                                      staticClass: "px-3 pt-6",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "title-on-border",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "todoPrioritiesColor"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-inline-block",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "16px",
                                                                },
                                                                attrs: {
                                                                  id: "lowPriorityLabel",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "lowLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "pb-color-picker",
                                                              {
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "pl-0",
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "",
                                                                                        "x-large":
                                                                                          "",
                                                                                        color:
                                                                                          _vm.lowPriorityColor,
                                                                                        "aria-labelledby":
                                                                                          "lowPriorityLabel",
                                                                                      },
                                                                                  },
                                                                                  "v-btn",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    class:
                                                                                      {
                                                                                        outlined:
                                                                                          _vm.lowPriorityColor ===
                                                                                          "#FFFFFF",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fas fa-square"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm.lowPriorityColor,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.lowPriorityColor =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "lowPriorityColor",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-inline-block",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "16px",
                                                                },
                                                                attrs: {
                                                                  id: "mediumPriorityLabel",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "mediumLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "pb-color-picker",
                                                              {
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "pl-0",
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "",
                                                                                        "x-large":
                                                                                          "",
                                                                                        color:
                                                                                          _vm.mediumPriorityColor,
                                                                                        "aria-labelledby":
                                                                                          "mediumPriorityLabel",
                                                                                      },
                                                                                  },
                                                                                  "v-btn",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    class:
                                                                                      {
                                                                                        outlined:
                                                                                          _vm.mediumPriorityColor ===
                                                                                          "#FFFFFF",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fas fa-square"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm.mediumPriorityColor,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.mediumPriorityColor =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "mediumPriorityColor",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-inline-block",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "16px",
                                                                },
                                                                attrs: {
                                                                  id: "highPriorityLabel",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "highLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "pb-color-picker",
                                                              {
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "pl-0",
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "",
                                                                                        "x-large":
                                                                                          "",
                                                                                        color:
                                                                                          _vm.highPriorityColor,
                                                                                        "aria-labelledby":
                                                                                          "highPriorityLabel",
                                                                                      },
                                                                                  },
                                                                                  "v-btn",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    class:
                                                                                      {
                                                                                        outlined:
                                                                                          _vm.highPriorityColor ===
                                                                                          "#FFFFFF",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fas fa-square"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm.highPriorityColor,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.highPriorityColor =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "highPriorityColor",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end px-10 pt-5" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "175px",
                                    color: "error",
                                    disabled: _vm.isNotDirty,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.saveSettings.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
        ],
        2
      ),
      _c(
        "pb-base-modal",
        {
          attrs: { maxWidth: 500, expandable: false, withActions: false },
          on: {
            apply: function ($event) {
              _vm.customFontDialog = _vm.saveFont()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(_vm._s(_vm.$t("uploadFontLabel")))]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.customFontDialog,
            callback: function ($$v) {
              _vm.customFontDialog = $$v
            },
            expression: "customFontDialog",
          },
        },
        [
          _c(
            "v-form",
            { ref: "uploadForm" },
            [
              _c(
                "v-container",
                { staticClass: "pb-7" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              "hide-details": "auto",
                              label: _vm.$t("fontFamilyLabel") + "*",
                              "prepend-inner-icon": "fal fa-font",
                              required: "",
                              rules: _vm.localRules,
                              "aria-required": "true",
                            },
                            model: {
                              value: _vm.fontFamily,
                              callback: function ($$v) {
                                _vm.fontFamily = $$v
                              },
                              expression: "fontFamily",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "pt-0 mt-0",
                              attrs: {
                                row: "",
                                dense: "",
                                "hide-details": "auto",
                                column: "",
                              },
                              model: {
                                value: _vm.addCustomFontType,
                                callback: function ($$v) {
                                  _vm.addCustomFontType = $$v
                                },
                                expression: "addCustomFontType",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: _vm.$t("fileLabel"),
                                  value: "F",
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: _vm.$t("urlLabel"),
                                  value: "U",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.addCustomFontType === "F"
                            ? _c("v-file-input", {
                                attrs: {
                                  outlined: "",
                                  "hide-details": "auto",
                                  placeholder:
                                    _vm.$t("fontFileLabel") + " (*.ttf, *.otf)",
                                  label:
                                    _vm.$t("fontFileLabel") + " (*.ttf, *.otf)",
                                  "prepend-inner-icon": "mdi-paperclip",
                                  "prepend-icon": "",
                                  accept: ".ttf, .otf",
                                  required: "",
                                  rules: _vm.localRules,
                                  clearable: "",
                                  "aria-required": "true",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function ({ text }) {
                                        return [
                                          _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                label: "",
                                                color: "primary",
                                              },
                                            },
                                            [_vm._v(" " + _vm._s(text) + " ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  590729088
                                ),
                                model: {
                                  value: _vm.fontFile,
                                  callback: function ($$v) {
                                    _vm.fontFile = $$v
                                  },
                                  expression: "fontFile",
                                },
                              })
                            : _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  "hide-details": "auto",
                                  outline: "",
                                  label:
                                    _vm.$t("fontUrlLabel") + " (*.ttf, *.otf)",
                                  rules: _vm.urlRules,
                                  "aria-required": "true",
                                },
                                model: {
                                  value: _vm.fontURL,
                                  callback: function ($$v) {
                                    _vm.fontURL = $$v
                                  },
                                  expression: "fontURL",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("input", {
        ref: "fileInput",
        staticClass: "d-none",
        attrs: {
          type: "file",
          accept: ".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*",
        },
        on: { change: _vm.onLogoSelected },
      }),
      _c(
        "pb-confirm",
        { ref: "confirm" },
        [
          _vm.showGlobalFontOptions
            ? [
                _c(
                  "v-chip-group",
                  {
                    attrs: {
                      "active-class": "accent2--text font-weight-bold",
                      multiple: "",
                    },
                    model: {
                      value: _vm.globalFontOptionsSelected,
                      callback: function ($$v) {
                        _vm.globalFontOptionsSelected = $$v
                      },
                      expression: "globalFontOptionsSelected",
                    },
                  },
                  _vm._l(_vm.globalFontItems, function (item, i) {
                    return _c(
                      "v-chip",
                      {
                        key: `global-font-option-${i}`,
                        attrs: { value: item.value, filter: "", outlined: "" },
                      },
                      [_vm._v(_vm._s(item.text))]
                    )
                  }),
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }