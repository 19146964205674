
import { autoSaveIntervals, dateDelimiterItems, dateFormatItems, fonts as constantFont, fontSizes, fontStyles, iconStyles, lessonHeadingsAlignment, lessonHeadingsOrder, nameOrderItems, orientationTypes, printLayoutItems, retrieveIntervals, urlFormat, weekDays, displaySettingsTabs, globalFontItems, contrastModeColors, lightModeColors } from '@/constants';
import FileServices from '@/services/file-services';
import CommonUtils from '@/utils/common-utils';
import DisplayFormatOptions from '@/components/core/DisplayFormatOptions.vue';
import Confirm from '@/components/core/Confirm.vue';
import ld from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { namespace } from 'vuex-class';
import EventStickerImage from '@/components/stickers/EventStickerImage.vue';

const settings = namespace('settings');
const index = namespace('index');

@Component({
  components: {
    draggable,
    DisplayFormatOptions,
    EventStickerImage
  }
})
export default class DisplaySettings extends Vue {
    tab = 0;
    customFontDialog = false;
    selectingLogo = false;
    logoFile: File | any = {};
    fontFile: File | unknown = [];
    fontFamily = '';
    fontURL = '';
    addCustomFontType = 'F';
    showGlobalFontOptions = false;
    globalFontOptionsSelected: Array<any> = [];

    @settings.Getter('getUserInfo')
    userInfo!: any;

    @settings.Getter('getLessonSectionsGrouping')
    lessonSectionsGrouping!: any;

    @settings.Getter('getLessonTitleStyling')
    lessonTitleStyling!: any;

    @settings.Getter('getAttachmentsStyling')
    attachmentsStyling!: any;

    @settings.Getter('getAssessmentsStyling')
    assessmentsStyling!: any;

    @settings.Getter('getAssignmentsStyling')
    assignmentsStyling!: any;

    @settings.Getter('getOtherSettings')
    otherSettings!: any;

    @settings.Getter('getLessonRetrieval')
    lessonRetrieval!: any;

    @settings.Getter('getLessonOrientationSettings')
    orientationSettings!: any;

    @settings.Getter('getLessonHeadings')
    lessonHeadings!: any;

    @settings.Getter('getLessonStatusSettings')
    lessonStatusSettings!: any;

    @settings.Getter('getEventStyling')
    eventStyling!: any;

    @settings.Getter('getStickerStyling')
    stickerStyling!: any;

    @settings.Getter('getStickeSettings')
    stickerSettings!: any;

    @settings.Getter('getDateStyling')
    dateStyling!: any;

    @settings.Getter('getClassworkStyling')
    classworkStyling!: any;

    @settings.Getter('getLessonSections')
    lessonSections!: any;

    @settings.Action('save')
    saveDisplaySettings!: (params?: any) => Promise<any>;

    @settings.Action
    reloadSettings!: (params?: any) => Promise<any>;

    @settings.Getter('getFontItems')
    fonts!: Array<any>;

    @settings.Getter('getGlobalLanguageItems')
    languages!: Array<any>;

    @settings.Action
    updateGlobalFontStyle!: (params?: any) => Promise<any>;

    @index.Action
    disableResizeable!: () => Promise<any>;

    @index.Action
    enableResizeable!: () => Promise<any>;

    @index.Getter
    hasBanner!: boolean;

    style = {}

    $refs!: {
      fileInput: HTMLElement,
      confirm: Confirm,
      uploadForm: Vue & { validate: () => boolean, resetValidation: () => void }
    }

    @Watch('tab')
    onTabChange() {
      this.updatedSettings = {};
      this.logoFile = null;
      this.fontFile = null;
      this.fontFamily = '';
      this.fontURL = '';
      this.addCustomFontType = 'F';
    }

    get isTeacher():boolean {
      return this.$currentUser.isTeacher;
    }

    get localRules() {
      return [
        (v: any) => !!v || this.$t('requiredLabel')
      ];
    }

    get urlRules() {
      return this.localRules.concat([
        (v: string) => urlFormat.test(v) || this.$t('invalidURLFormatMsg')
      ]);
    }

    get isNotDirty() {
      return !(this.updatedSettings && Object.keys(this.updatedSettings).length > 0);
    }

    get scrollHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) return this.hasBanner ? 'calc(100vh - 230px)' : 'calc(100vh - 150px)';
      return this.hasBanner ? 'calc(100vh - 400px)' : 'calc(100vh - 300px)';
    }

    get hasLogo() {
      return CommonUtils.hasText(this.center) && this.center !== 'blank';
    }

    get fontSizes() {
      return fontSizes;
    }

    get orientationTypes() {
      return orientationTypes;
    }

    get lessonHeadingsOrder() {
      return lessonHeadingsOrder;
    }

    get lessonHeadingsAlignment() {
      return lessonHeadingsAlignment;
    }

    get autoSaveIntervals() {
      return autoSaveIntervals;
    }

    get retrieveIntervals() {
      return retrieveIntervals;
    }

    get printLayoutItems() {
      return printLayoutItems;
    }

    get weekDays() {
      return weekDays;
    }

    get customFontHeaders() {
      return [
        {
          text: this.$t('fontFamilyLabel'),
          align: 'start',
          value: 'fontFamily'
        },
        {
          text: this.$t('fontUrlLabel'),
          value: 'fontURL'
        },
        {
          width: '60px',
          sortable: false
        }
      ]
    }

    get globalFontParams() {
      const params: any = {};
      globalFontItems.forEach(item => {
        params[item.value] = this.globalFontOptionsSelected.includes(item.value);
      });
      return params;
    }

    get globalFontItems() {
      return globalFontItems;
    }

    get updatedSettings(): any {
      return this.$store.state.settings.updatedSettings;
    }

    set updatedSettings(value: any) {
      this.$store.commit('settings/setUpdatedSettings', value);
    }

    get globalFontType(): string {
      return this.getValue('globalFontType', this.otherSettings.globalFontType || '2');
    }

    set globalFontType(value: string) {
      this.setValue('globalFontType', value);
    }

    get globalFontSize(): string {
      return this.getValue('globalFontSize', this.otherSettings.globalFontSize || '10');
    }

    set globalFontSize(value: string) {
      this.setValue('globalFontSize', value);
    }

    get plansTextScale() {
      return this.getValue('plansTextScale', this.otherSettings.plansTextScale || 100);
    }

    set plansTextScale(value: number) {
      this.setValue('plansTextScale', value);
    }

    get darkMode(): boolean {
      return this.getValue('darkMode', CommonUtils.getOrDefaultTo(this.otherSettings.darkMode, false));
    }

    set darkMode(value: boolean) {
      this.setValue('darkMode', value);
    }

    get autoColorScheme(): boolean {
      return this.getValue('autoColorScheme', CommonUtils.getOrDefaultTo(this.otherSettings.autoColorScheme, true));
    }

    set autoColorScheme(value: boolean) {
      this.setValue('autoColorScheme', value);
    }

    get highContrastMode(): boolean {
      return this.getValue('highContrastMode', CommonUtils.getOrDefaultTo(this.otherSettings.highContrastMode, true));
    }

    set highContrastMode(value: boolean) {
      if (value) {
        this.autoColorScheme = false;
      }
      this.setValue('highContrastMode', value);
    }

    get defaultEditorMode(): string {
      return this.getValue('autoColorScheme', this.otherSettings.defaultEditorMode || 'split');
    }

    set defaultEditorMode(value: string) {
      this.setValue('defaultEditorMode', value);
    }

    get cardElevation(): boolean {
      return this.getValue('cardElevation', this.otherSettings.cardElevation || 0);
    }

    set cardElevation(value: boolean) {
      this.setValue('cardElevation', value);
    }

    get plansBackgroundColor(): boolean {
      return this.getValue('plansBackgroundColor', this.otherSettings.plansBackgroundColor || 0);
    }

    set plansBackgroundColor(value: boolean) {
      this.setValue('plansBackgroundColor', value);
    }

    get lessonSectionsGroupingValue(): number {
      return this.getValue('lessonSectionsGroupingValue', this.lessonSectionsGrouping.lessonSectionsGroupingValue || 1);
    }

    set lessonSectionsGroupingValue(value: number) {
      this.setValue('lessonSectionsGroupingValue', value);
    }

    get defaultGroupingLessonSectionOrder(): number {
      return this.getValue('defaultGroupingLessonSectionOrder', this.lessonSectionsGrouping.defaultGroupingLessonSectionOrder || 1);
    }

    set defaultGroupingLessonSectionOrder(value: number) {
      this.setValue('defaultGroupingLessonSectionOrder', value);
    }

    get defaultGroupingLinkedLessonSectionOrder(): number {
      return this.getValue('defaultGroupingLinkedLessonSectionOrder', this.lessonSectionsGrouping.defaultGroupingLinkedLessonSectionOrder || 3);
    }

    set defaultGroupingLinkedLessonSectionOrder(value: number) {
      this.setValue('defaultGroupingLinkedLessonSectionOrder', value);
    }

    get defaultGroupingLinkedUnitSectionOrder(): number {
      return this.getValue('defaultGroupingLinkedUnitSectionOrder', this.lessonSectionsGrouping.defaultGroupingLinkedUnitSectionOrder || 4);
    }

    set defaultGroupingLinkedUnitSectionOrder(value: number) {
      this.setValue('defaultGroupingLinkedUnitSectionOrder', value);
    }

    get defaultGroupingUnitSectionOrder(): number {
      return this.getValue('defaultGroupingUnitSectionOrder', this.lessonSectionsGrouping.defaultGroupingUnitSectionOrder || 2);
    }

    set defaultGroupingUnitSectionOrder(value: number) {
      this.setValue('defaultGroupingUnitSectionOrder', value);
    }

    get groupedGroupingLessonOrder(): number {
      return this.getValue('groupedGroupingLessonOrder', this.lessonSectionsGrouping.groupedGroupingLessonOrder || 2);
    }

    set groupedGroupingLessonOrder(value: number) {
      this.setValue('groupedGroupingLessonOrder', value);
    }

    get groupedGroupingLinkedLessonOrder(): number {
      return this.getValue('groupedGroupingLinkedLessonOrder', this.lessonSectionsGrouping.groupedGroupingLinkedLessonOrder || 3);
    }

    set groupedGroupingLinkedLessonOrder(value: number) {
      this.setValue('groupedGroupingLinkedLessonOrder', value);
    }

    get groupedGroupingLinkedUnitOrder(): number {
      return this.getValue('groupedGroupingLinkedUnitOrder', this.lessonSectionsGrouping.groupedGroupingLinkedUnitOrder || 4);
    }

    set groupedGroupingLinkedUnitOrder(value: number) {
      this.setValue('groupedGroupingLinkedUnitOrder', value);
    }

    get groupedGroupingUnitOrder(): number {
      return this.getValue('groupedGroupingUnitOrder', this.lessonSectionsGrouping.groupedGroupingUnitOrder || 1);
    }

    set groupedGroupingUnitOrder(value: number) {
      this.setValue('groupedGroupingUnitOrder', value);
    }

    get autosaveInterval(): number {
      return this.getNumber('autosaveInterval', +this.lessonRetrieval.autosaveInterval);
    }

    set autosaveInterval(value: number) {
      this.setValue('autosaveInterval', value);
    }

    get fetchWeekSize(): number {
      return this.getNumber('fetchWeekSize', +this.lessonRetrieval.fetchWeekSize);
    }

    set fetchWeekSize(value: number) {
      this.setValue('fetchWeekSize', value);
    }

    get sectionGrouping() {
      const grouping = [
        {
          order: this.defaultGroupingLessonSectionOrder,
          value: 'defaultGroupingLessonSectionOrder',
          text: this.$t('contentsLabel', { text: this.$t('lessonLabel') })
        },
        {
          order: this.defaultGroupingUnitSectionOrder,
          value: 'defaultGroupingUnitSectionOrder',
          text: this.$t('contentsLabel', { text: this.$t('unitLabel') }),
          tag: 'U'
        },
        {
          order: this.defaultGroupingLinkedLessonSectionOrder,
          value: 'defaultGroupingLinkedLessonSectionOrder',
          text: this.$t('contentsLabel', { text: this.$t('linkedLessonLabel') }),
          tag: 'LL'
        },
        {
          order: this.defaultGroupingLinkedUnitSectionOrder,
          value: 'defaultGroupingLinkedUnitSectionOrder',
          text: this.$t('contentsLabel', { text: this.$t('linkedUnitLabel') }),
          tag: 'LU'
        }
      ];
      grouping.sort((a, b) => a.order - b.order)
      return grouping;
    }

    set sectionGrouping(grouping: Array<any>) {
      grouping.forEach((g, i) => {
        this.setValue(g.value, i + 1);
      })
    }

    moveSectionUp(index: any) {
      const clone = ld.cloneDeep(this.sectionGrouping);
      clone[index - 1] = this.sectionGrouping[index];
      clone[index] = this.sectionGrouping[index - 1];
      this.sectionGrouping = clone;
      (this.$refs as any)['sectionButton' + (index - 1)][0].$el.focus();
    }

    moveSectionDown(index: any) {
      const clone = ld.cloneDeep(this.sectionGrouping);
      clone[index + 1] = this.sectionGrouping[index];
      clone[index] = this.sectionGrouping[index + 1];
      this.sectionGrouping = clone;
      (this.$refs as any)['sectionButton' + (index + 1)][0].$el.focus();
    }

    get typeGrouping() {
      const grouping = [
        {
          order: this.groupedGroupingLessonOrder,
          value: 'groupedGroupingLessonOrder',
          text: this.$t('lessonLabel')
        },
        {
          order: this.groupedGroupingUnitOrder,
          value: 'groupedGroupingUnitOrder',
          text: this.$t('unitLabel'),
          tag: 'U'
        },
        {
          order: this.groupedGroupingLinkedLessonOrder,
          value: 'groupedGroupingLinkedLessonOrder',
          text: this.$t('linkedLessonLabel'),
          tag: 'LL'
        },
        {
          order: this.groupedGroupingLinkedUnitOrder,
          value: 'groupedGroupingLinkedUnitOrder',
          text: this.$t('linkedUnitLabel'),
          tag: 'LU'
        }
      ];
      grouping.sort((a, b) => a.order - b.order)
      return grouping;
    }

    set typeGrouping(grouping: Array<any>) {
      grouping.forEach((g, i) => {
        this.setValue(g.value, i + 1);
      })
    }

    moveTypeUp(index: any) {
      const clone = ld.cloneDeep(this.typeGrouping);
      clone[index - 1] = this.typeGrouping[index];
      clone[index] = this.typeGrouping[index - 1];
      this.typeGrouping = clone;
      (this.$refs as any)['typeButton' + (index - 1)][0].$el.focus();
    }

    moveTypeDown(index: any) {
      const clone = ld.cloneDeep(this.typeGrouping);
      clone[index + 1] = this.typeGrouping[index];
      clone[index] = this.typeGrouping[index + 1];
      this.typeGrouping = clone;
      (this.$refs as any)['typeButton' + (index + 1)][0].$el.focus();
    }

    get lessonTitleColor(): string {
      return this.getValue('lessonTitleColor', this.lessonTitleStyling.lessonTitleColor);
    }

    set lessonTitleColor(value: string) {
      this.setValue('lessonTitleColor', value);
    }

    get lessonTitleFont(): string {
      return this.getValue('lessonTitleFont', this.lessonTitleStyling.lessonTitleFont);
    }

    set lessonTitleFont(value: string) {
      this.setValue('lessonTitleFont', value);
    }

    get lessonTitleLabel(): string {
      return this.getValue('lessonTitleLabel', this.lessonTitleStyling.lessonTitleLabel);
    }

    set lessonTitleLabel(value: string) {
      this.setValue('lessonTitleLabel', value);
    }

    get lessonTitleLabelBold(): string {
      return this.getValue('lessonTitleLabelBold', this.lessonTitleStyling.lessonTitleLabelBold);
    }

    set lessonTitleLabelBold(value: string) {
      this.setValue('lessonTitleLabelBold', value);
    }

    get lessonTitleLabelFillColor(): string {
      return this.getValue('lessonTitleLabelFillColor', this.lessonTitleStyling.lessonTitleLabelFillColor);
    }

    set lessonTitleLabelFillColor(value: string) {
      this.setValue('lessonTitleLabelFillColor', value);
    }

    get lessonTitleLabelItalic(): string {
      return this.getValue('lessonTitleLabelItalic', this.lessonTitleStyling.lessonTitleLabelItalic);
    }

    set lessonTitleLabelItalic(value: string) {
      this.setValue('lessonTitleLabelItalic', value);
    }

    get lessonTitleLabelUnderline(): string {
      return this.getValue('lessonTitleLabelUnderline', this.lessonTitleStyling.lessonTitleLabelUnderline);
    }

    set lessonTitleLabelUnderline(value: string) {
      this.setValue('lessonTitleLabelUnderline', value);
    }

    get lessonTitleSize(): string {
      return this.getValue('lessonTitleSize', this.lessonTitleStyling.lessonTitleSize);
    }

    set lessonTitleSize(value: string) {
      this.setValue('lessonTitleSize', value);
    }

    get attachmentsColor(): string {
      return this.getValue('attachmentsColor', this.attachmentsStyling.attachmentsColor);
    }

    set attachmentsColor(value: string) {
      this.setValue('attachmentsColor', value);
    }

    get attachmentsFont(): string {
      return this.getValue('attachmentsFont', this.attachmentsStyling.attachmentsFont);
    }

    set attachmentsFont(value: string) {
      this.setValue('attachmentsFont', value);
    }

    get attachmentsHide(): string {
      return this.getValue('attachmentsHide', this.attachmentsStyling.attachmentsHide);
    }

    set attachmentsHide(value: string) {
      this.setValue('attachmentsHide', value);
    }

    get attachmentsLabelBold(): string {
      return this.getValue('attachmentsLabelBold', this.attachmentsStyling.attachmentsLabelBold);
    }

    set attachmentsLabelBold(value: string) {
      this.setValue('attachmentsLabelBold', value);
    }

    get attachmentsLabelFillColor(): string {
      return this.getValue('attachmentsLabelFillColor', this.attachmentsStyling.attachmentsLabelFillColor);
    }

    set attachmentsLabelFillColor(value: string) {
      this.setValue('attachmentsLabelFillColor', value);
    }

    get attachmentsLabelItalic(): string {
      return this.getValue('attachmentsLabelItalic', this.attachmentsStyling.attachmentsLabelItalic);
    }

    set attachmentsLabelItalic(value: string) {
      this.setValue('attachmentsLabelItalic', value);
    }

    get attachmentsLabelUnderline(): string {
      return this.getValue('attachmentsLabelUnderline', this.attachmentsStyling.attachmentsLabelUnderline);
    }

    set attachmentsLabelUnderline(value: string) {
      this.setValue('attachmentsLabelUnderline', value);
    }

    get attachmentsSize(): string {
      return this.getValue('attachmentsSize', this.attachmentsStyling.attachmentsSize);
    }

    set attachmentsSize(value: string) {
      this.setValue('attachmentsSize', value);
    }

    get attachmentsTextBold(): string {
      return this.getValue('attachmentsTextBold', this.attachmentsStyling.attachmentsTextBold);
    }

    set attachmentsTextBold(value: string) {
      this.setValue('attachmentsTextBold', value);
    }

    get attachmentsTextColor(): string {
      return this.getValue('attachmentsTextColor', this.attachmentsStyling.attachmentsTextColor);
    }

    set attachmentsTextColor(value: string) {
      this.setValue('attachmentsTextColor', value);
    }

    get attachmentsTextFillColor(): string {
      return this.getValue('attachmentsTextFillColor', this.attachmentsStyling.attachmentsTextFillColor);
    }

    set attachmentsTextFillColor(value: string) {
      this.setValue('attachmentsTextFillColor', value);
    }

    get attachmentsTextFont(): string {
      return this.getValue('attachmentsTextFont', this.attachmentsStyling.attachmentsTextFont);
    }

    set attachmentsTextFont(value: string) {
      this.setValue('attachmentsTextFont', value);
    }

    get attachmentsTextItalic(): string {
      return this.getValue('attachmentsTextItalic', this.attachmentsStyling.attachmentsTextItalic);
    }

    set attachmentsTextItalic(value: string) {
      this.setValue('attachmentsTextItalic', value);
    }

    get attachmentsTextSize(): string {
      return this.getValue('attachmentsTextSize', this.attachmentsStyling.attachmentsTextSize);
    }

    set attachmentsTextSize(value: string) {
      this.setValue('attachmentsTextSize', value);
    }

    get attachmentsTextUnderline(): string {
      return this.getValue('attachmentsTextUnderline', this.attachmentsStyling.attachmentsTextUnderline);
    }

    set attachmentsTextUnderline(value: string) {
      this.setValue('attachmentsTextUnderline', value);
    }

    get assessmentsColor(): string {
      return this.getValue('assessmentsColor', this.assessmentsStyling.assessmentsColor);
    }

    set assessmentsColor(value: string) {
      this.setValue('assessmentsColor', value);
    }

    get assessmentsFont(): string {
      return this.getValue('assessmentsFont', this.assessmentsStyling.assessmentsFont);
    }

    set assessmentsFont(value: string) {
      this.setValue('assessmentsFont', value);
    }

    get assessmentsHide(): string {
      return this.getValue('assessmentsHide', this.assessmentsStyling.assessmentsHide);
    }

    set assessmentsHide(value: string) {
      this.setValue('assessmentsHide', value);
    }

    get assessmentsLabelBold(): string {
      return this.getValue('assessmentsLabelBold', this.assessmentsStyling.assessmentsLabelBold);
    }

    set assessmentsLabelBold(value: string) {
      this.setValue('assessmentsLabelBold', value);
    }

    get assessmentsLabelFillColor(): string {
      return this.getValue('assessmentsLabelFillColor', this.assessmentsStyling.assessmentsLabelFillColor);
    }

    set assessmentsLabelFillColor(value: string) {
      this.setValue('assessmentsLabelFillColor', value);
    }

    get assessmentsLabelItalic(): string {
      return this.getValue('assessmentsLabelItalic', this.assessmentsStyling.assessmentsLabelItalic);
    }

    set assessmentsLabelItalic(value: string) {
      this.setValue('assessmentsLabelItalic', value);
    }

    get assessmentsLabelUnderline(): string {
      return this.getValue('assessmentsLabelUnderline', this.assessmentsStyling.assessmentsLabelUnderline);
    }

    set assessmentsLabelUnderline(value: string) {
      this.setValue('assessmentsLabelUnderline', value);
    }

    get assessmentsSize(): string {
      return this.getValue('assessmentsSize', this.assessmentsStyling.assessmentsSize);
    }

    set assessmentsSize(value: string) {
      this.setValue('assessmentsSize', value);
    }

    get assessmentsTextBold(): string {
      return this.getValue('assessmentsTextBold', this.assessmentsStyling.assessmentsTextBold);
    }

    set assessmentsTextBold(value: string) {
      this.setValue('assessmentsTextBold', value);
    }

    get assessmentsTextColor(): string {
      return this.getValue('assessmentsTextColor', this.assessmentsStyling.assessmentsTextColor);
    }

    set assessmentsTextColor(value: string) {
      this.setValue('assessmentsTextColor', value);
    }

    get assessmentsTextFillColor(): string {
      return this.getValue('assessmentsTextFillColor', this.assessmentsStyling.assessmentsTextFillColor);
    }

    set assessmentsTextFillColor(value: string) {
      this.setValue('assessmentsTextFillColor', value);
    }

    get assessmentsTextFont(): string {
      return this.getValue('assessmentsTextFont', this.assessmentsStyling.assessmentsTextFont);
    }

    set assessmentsTextFont(value: string) {
      this.setValue('assessmentsTextFont', value);
    }

    get assessmentsTextItalic(): string {
      return this.getValue('assessmentsTextItalic', this.assessmentsStyling.assessmentsTextItalic);
    }

    set assessmentsTextItalic(value: string) {
      this.setValue('assessmentsTextItalic', value);
    }

    get assessmentsTextSize(): string {
      return this.getValue('assessmentsTextSize', this.assessmentsStyling.assessmentsTextSize);
    }

    set assessmentsTextSize(value: string) {
      this.setValue('assessmentsTextSize', value);
    }

    get assessmentsTextUnderline(): string {
      return this.getValue('assessmentsTextUnderline', this.assessmentsStyling.assessmentsTextUnderline);
    }

    set assessmentsTextUnderline(value: string) {
      this.setValue('assessmentsTextUnderline', value);
    }

    get assignmentsColor(): string {
      return this.getValue('assignmentsColor', this.assignmentsStyling.assignmentsColor);
    }

    set assignmentsColor(value: string) {
      this.setValue('assignmentsColor', value);
    }

    get assignmentsFont(): string {
      return this.getValue('assignmentsFont', this.assignmentsStyling.assignmentsFont);
    }

    set assignmentsFont(value: string) {
      this.setValue('assignmentsFont', value);
    }

    get assignmentsHide(): string {
      return this.getValue('assignmentsHide', this.assignmentsStyling.assignmentsHide);
    }

    set assignmentsHide(value: string) {
      this.setValue('assignmentsHide', value);
    }

    get assignmentsLabelBold(): string {
      return this.getValue('assignmentsLabelBold', this.assignmentsStyling.assignmentsLabelBold);
    }

    set assignmentsLabelBold(value: string) {
      this.setValue('assignmentsLabelBold', value);
    }

    get assignmentsLabelFillColor(): string {
      return this.getValue('assignmentsLabelFillColor', this.assignmentsStyling.assignmentsLabelFillColor);
    }

    set assignmentsLabelFillColor(value: string) {
      this.setValue('assignmentsLabelFillColor', value);
    }

    get assignmentsLabelItalic(): string {
      return this.getValue('assignmentsLabelItalic', this.assignmentsStyling.assignmentsLabelItalic);
    }

    set assignmentsLabelItalic(value: string) {
      this.setValue('assignmentsLabelItalic', value);
    }

    get assignmentsLabelUnderline(): string {
      return this.getValue('assignmentsLabelUnderline', this.assignmentsStyling.assignmentsLabelUnderline);
    }

    set assignmentsLabelUnderline(value: string) {
      this.setValue('assignmentsLabelUnderline', value);
    }

    get assignmentsSize(): string {
      return this.getValue('assignmentsSize', this.assignmentsStyling.assignmentsSize);
    }

    set assignmentsSize(value: string) {
      this.setValue('assignmentsSize', value);
    }

    get assignmentsTextBold(): string {
      return this.getValue('assignmentsTextBold', this.assignmentsStyling.assignmentsTextBold);
    }

    set assignmentsTextBold(value: string) {
      this.setValue('assignmentsTextBold', value);
    }

    get assignmentsTextColor(): string {
      return this.getValue('assignmentsTextColor', this.assignmentsStyling.assignmentsTextColor);
    }

    set assignmentsTextColor(value: string) {
      this.setValue('assignmentsTextColor', value);
    }

    get assignmentsTextFillColor(): string {
      return this.getValue('assignmentsTextFillColor', this.assignmentsStyling.assignmentsTextFillColor);
    }

    set assignmentsTextFillColor(value: string) {
      this.setValue('assignmentsTextFillColor', value);
    }

    get assignmentsTextFont(): string {
      return this.getValue('assignmentsTextFont', this.assignmentsStyling.assignmentsTextFont);
    }

    set assignmentsTextFont(value: string) {
      this.setValue('assignmentsTextFont', value);
    }

    get assignmentsTextItalic(): string {
      return this.getValue('assignmentsTextItalic', this.assignmentsStyling.assignmentsTextItalic);
    }

    set assignmentsTextItalic(value: string) {
      this.setValue('assignmentsTextItalic', value);
    }

    get assignmentsTextSize(): string {
      return this.getValue('assignmentsTextSize', this.assignmentsStyling.assignmentsTextSize);
    }

    set assignmentsTextSize(value: string) {
      this.setValue('assignmentsTextSize', value);
    }

    get assignmentsTextUnderline(): string {
      return this.getValue('assignmentsTextUnderline', this.assignmentsStyling.assignmentsTextUnderline);
    }

    set assignmentsTextUnderline(value: string) {
      this.setValue('assignmentsTextUnderline', value);
    }

    get adjustToFit(): boolean {
      return CommonUtils.isTrue(this.getValue('adjustToFit', this.orientationSettings.adjustToFit || 'N'));
    }

    set adjustToFit(value: boolean) {
      this.setValue('adjustToFit', CommonUtils.booleanToString(value));
    }

    get viewOrientation(): number {
      return this.getValue('viewOrientation', +this.orientationSettings.viewOrientation);
    }

    set viewOrientation(value: number) {
      this.setValue('viewOrientation', value);
      if (this.orientationSettings.viewOrientation === value) {
        this.boxSize = this.orientationSettings.boxSize;
      } else if (value === 0) {
        this.boxSize = 100;
      } else if (value === 1) {
        this.boxSize = 5;
      }
    }

    get classHeadCentered() {
      return this.getValue('classHeadCentered', this.lessonHeadings.classHeadCentered);
    }

    set classHeadCentered(value: any) {
      this.setValue('classHeadCentered', value);
    }

    get classHeadOrder() {
      return this.getValue('classHeadOrder', this.lessonHeadings.classHeadOrder);
    }

    set classHeadOrder(value: any) {
      this.setValue('classHeadOrder', value);
    }

    get statusTextFontSize() {
      return (this.getValue('statusTextFontSize', this.lessonStatusSettings.statusTextFontSize || '')).replace('pt', '');
    }

    set statusTextFontSize(value: any) {
      this.setValue('statusTextFontSize', value);
    }

    get displayStatusText() {
      return CommonUtils.isTrue(this.getValue('displayStatusText', this.lessonStatusSettings.displayStatusText));
    }

    set displayStatusText(value: any) {
      this.setValue('displayStatusText', value);
    }

    get displaySesctionTags() {
      return CommonUtils.isTrue(this.getValue('displaySesctionTags', this.otherSettings.displaySesctionTags));
    }

    set displaySesctionTags(value: any) {
      this.setValue('displaySesctionTags', value);
    }

    get displayToolTipStandardDetails() {
      return CommonUtils.isTrue(this.getValue('displayToolTipStandardDetails', this.otherSettings.displayToolTipStandardDetails));
    }

    set displayToolTipStandardDetails(value: any) {
      this.setValue('displayToolTipStandardDetails', value);
    }

    get equalSize(): boolean {
      return CommonUtils.isTrue(this.getValue('equalSize', this.orientationSettings.equalSize || 'N'));
    }

    set equalSize(value: boolean) {
      this.setValue('equalSize', CommonUtils.booleanToString(value));
      this.adjustToFit = value;
    }

    get boxSize(): number {
      return this.getValue('boxSize', this.orientationSettings.boxSize);
    }

    set boxSize(value: number) {
      this.setValue('boxSize', value);
    }

    get printLayout() {
      return this.$store.getters['settings/getPrintLayout']
    }

    get printHeaders() {
      return this.getValue('printHeaders', ld.cloneDeep(this.printLayout));
    }

    set printHeaders(value: any) {
      this.setValue('printHeaders', value);
    }

    get nameCommaSeparatedDisabled() {
      return this.nameOrder === 3 || this.nameOrder === 4;
    }

    get center() {
      return this.getValue('center', this.printHeaders[0].selection || 'https://s3.amazonaws.com/planbook-assets/images/planbook-icon.png');
    }

    set center(value: any) {
      this.setValue('center', value);
      const newPrintHeaders = ld.cloneDeep(this.printHeaders);
      newPrintHeaders[0].selection = value;
      this.printHeaders = newPrintHeaders;
    }

    get topLeftPrint() {
      return this.getValue('topLeftPrint', this.getCurrentHeaderValue(this.topLeftCustom, this.printHeaders[1]));
    }

    set topLeftPrint(value: any) {
      this.setValue('topLeftPrint', value);
      const newPrintHeaders = ld.cloneDeep(this.printHeaders);
      if (this.topLeftCustom) {
        newPrintHeaders[1].customText = value;
      } else {
        newPrintHeaders[1].selection = value;
      }
      this.printHeaders = newPrintHeaders;
    }

    get topRightPrint() {
      return this.getValue('topRightPrint', this.getCurrentHeaderValue(this.topRightCustom, this.printHeaders[2]));
    }

    set topRightPrint(value: any) {
      this.setValue('topRightPrint', value);
      const newPrintHeaders = ld.cloneDeep(this.printHeaders);
      if (this.topRightCustom) {
        newPrintHeaders[2].customText = value;
      } else {
        newPrintHeaders[2].selection = value;
      }
      this.printHeaders = newPrintHeaders;
    }

    get topLeftPrint2() {
      return this.getValue('topLeftPrint2', this.getCurrentHeaderValue(this.topLeftCustom2, this.printHeaders[3]));
    }

    set topLeftPrint2(value: any) {
      this.setValue('topLeftPrint2', value);
      const newPrintHeaders = ld.cloneDeep(this.printHeaders);
      if (this.topLeftCustom2) {
        newPrintHeaders[3].customText = value;
      } else {
        newPrintHeaders[3].selection = value;
      }
      this.printHeaders = newPrintHeaders;
    }

    get topRightPrint2() {
      return this.getValue('topRightPrint2', this.getCurrentHeaderValue(this.topRightCustom2, this.printHeaders[4]));
    }

    set topRightPrint2(value: any) {
      this.setValue('topRightPrint2', value);
      const newPrintHeaders = ld.cloneDeep(this.printHeaders);
      if (this.topRightCustom2) {
        newPrintHeaders[4].customText = value;
      } else {
        newPrintHeaders[4].selection = value;
      }
      this.printHeaders = newPrintHeaders;
    }

    get topLeftCustom(): boolean {
      return this.getValue('topLeftCustom', this.printHeaders[1].showCustomText);
    }

    set topLeftCustom(value: boolean) {
      this.setValue('topLeftCustom', value);
      const newPrintHeaders = ld.cloneDeep(this.printHeaders);
      newPrintHeaders[1].showCustomText = value;
      this.printHeaders = newPrintHeaders;
      this.topLeftPrint = this.getCurrentHeaderValue(value, this.printLayout[1], 'SY');
    }

    get topRightCustom(): boolean {
      return this.getValue('topRightCustom', this.printHeaders[2].showCustomText);
    }

    set topRightCustom(value: boolean) {
      this.setValue('topRightCustom', value);
      const newPrintHeaders = ld.cloneDeep(this.printHeaders);
      newPrintHeaders[2].showCustomText = value;
      this.printHeaders = newPrintHeaders;
      this.topRightPrint = this.getCurrentHeaderValue(value, this.printLayout[2], 'DR');
    }

    get topLeftCustom2(): boolean {
      return this.getValue('topLeftCustom2', this.printHeaders[3].showCustomText);
    }

    set topLeftCustom2(value: boolean) {
      this.setValue('topLeftCustom2', value);
      const newPrintHeaders = ld.cloneDeep(this.printHeaders);
      newPrintHeaders[3].showCustomText = value;
      this.printHeaders = newPrintHeaders;
      this.topLeftPrint2 = this.getCurrentHeaderValue(value, this.printLayout[3], 'TN');
    }

    get topRightCustom2(): boolean {
      return this.getValue('topRightCustom2', this.printHeaders[4].showCustomText);
    }

    set topRightCustom2(value: boolean) {
      this.setValue('topRightCustom2', value);
      const newPrintHeaders = ld.cloneDeep(this.printHeaders);
      newPrintHeaders[4].showCustomText = value;
      this.printHeaders = newPrintHeaders;
      this.topRightPrint2 = this.getCurrentHeaderValue(value, this.printLayout[4], 'NA');
    }

    isPrintLayoutItem(value: string) {
      return this.printLayoutItems.findIndex(item => item.value === value) > 0;
    }

    getCurrentHeaderValue(custom: boolean, currentLayoutSection: any, defaultSelection?: string) {
      if (custom) {
        if (currentLayoutSection.showCustomText) {
          const value = currentLayoutSection.customText || currentLayoutSection.selection;
          if (!this.isPrintLayoutItem(value)) {
            return value;
          }
        }
        return '';
      } else {
        if (currentLayoutSection.showCustomText) {
          return defaultSelection || '';
        } else {
          return currentLayoutSection.selection;
        }
      }
    }

    get lessonTitleStyle() {
      return {
        titleFont: this.lessonTitleFont,
        titleFontSize: this.lessonTitleSize,
        titleColor: this.lessonTitleColor,
        titleFillColor: this.lessonTitleLabelFillColor,
        titleBold: this.lessonTitleLabelBold,
        titleItalic: this.lessonTitleLabelItalic,
        titleUnderline: this.lessonTitleLabelUnderline
      }
    }

    set lessonTitleStyle(val: any) {
      this.lessonTitleFont = val.titleFont;
      this.lessonTitleSize = val.titleFontSize;
      this.lessonTitleColor = val.titleColor;
      this.lessonTitleLabelFillColor = val.titleFillColor;
      this.lessonTitleLabelBold = val.titleBold;
      this.lessonTitleLabelItalic = val.titleItalic;
      this.lessonTitleLabelUnderline = val.titleUnderline;
    }

    get attachmentsBodyStyle() {
      return {
        fontFamily: CommonUtils.getFontFamily(this.attachmentsTextFont),
        fontSize: this.attachmentsTextSize + 'pt',
        color: CommonUtils.getColor(this.attachmentsTextColor),
        backgroundColor: CommonUtils.getColor(this.attachmentsTextFillColor),
        fontWeight: CommonUtils.isTrue(this.attachmentsTextBold) ? 'bold' : 'normal',
        fontStyle: CommonUtils.isTrue(this.attachmentsTextItalic) ? 'italic' : 'normal',
        textDecoration: CommonUtils.isTrue(this.attachmentsTextUnderline) ? 'underline' : 'none'
      }
    }

    get attachmentsStyle() {
      return {
        titleFont: this.attachmentsFont,
        titleFontSize: this.attachmentsSize,
        titleColor: this.attachmentsColor,
        titleFillColor: this.attachmentsLabelFillColor,
        titleBold: this.attachmentsLabelBold,
        titleItalic: this.attachmentsLabelItalic,
        titleUnderline: this.attachmentsLabelUnderline,
        bodyFont: this.attachmentsTextFont,
        bodyFontSize: this.attachmentsTextSize,
        bodyColor: this.attachmentsTextColor,
        bodyFillColor: this.attachmentsTextFillColor,
        bodyBold: this.attachmentsTextBold,
        bodyItalic: this.attachmentsTextItalic,
        bodyUnderline: this.attachmentsTextUnderline,
        showTitle: CommonUtils.booleanToString(CommonUtils.isFalse(this.attachmentsHide))
      }
    }

    set attachmentsStyle(val: any) {
      this.attachmentsFont = val.titleFont;
      this.attachmentsSize = val.titleFontSize;
      this.attachmentsColor = val.titleColor;
      this.attachmentsLabelFillColor = val.titleFillColor;
      this.attachmentsLabelBold = val.titleBold;
      this.attachmentsLabelItalic = val.titleItalic;
      this.attachmentsLabelUnderline = val.titleUnderline;
      this.attachmentsTextFont = val.bodyFont;
      this.attachmentsTextSize = val.bodyFontSize;
      this.attachmentsTextColor = val.bodyColor;
      this.attachmentsTextFillColor = val.bodyFillColor;
      this.attachmentsTextBold = val.bodyBold;
      this.attachmentsTextItalic = val.bodyItalic;
      this.attachmentsTextUnderline = val.bodyUnderline;
      this.attachmentsHide = CommonUtils.booleanToString(CommonUtils.isFalse(val.showTitle));
    }

    get assessmentsStyle() {
      return {
        titleFont: this.assessmentsFont,
        titleFontSize: this.assessmentsSize,
        titleColor: this.assessmentsColor,
        titleFillColor: this.assessmentsLabelFillColor,
        titleBold: this.assessmentsLabelBold,
        titleItalic: this.assessmentsLabelItalic,
        titleUnderline: this.assessmentsLabelUnderline,
        bodyFont: this.assessmentsTextFont,
        bodyFontSize: this.assessmentsTextSize,
        bodyColor: this.assessmentsTextColor,
        bodyFillColor: this.assessmentsTextFillColor,
        bodyBold: this.assessmentsTextBold,
        bodyItalic: this.assessmentsTextItalic,
        bodyUnderline: this.assessmentsTextUnderline,
        showTitle: CommonUtils.booleanToString(CommonUtils.isFalse(this.assessmentsHide))
      }
    }

    set assessmentsStyle(val: any) {
      this.assessmentsFont = val.titleFont;
      this.assessmentsSize = val.titleFontSize;
      this.assessmentsColor = val.titleColor;
      this.assessmentsLabelFillColor = val.titleFillColor;
      this.assessmentsLabelBold = val.titleBold;
      this.assessmentsLabelItalic = val.titleItalic;
      this.assessmentsLabelUnderline = val.titleUnderline;
      this.assessmentsTextFont = val.bodyFont;
      this.assessmentsTextSize = val.bodyFontSize;
      this.assessmentsTextColor = val.bodyColor;
      this.assessmentsTextFillColor = val.bodyFillColor;
      this.assessmentsTextBold = val.bodyBold;
      this.assessmentsTextItalic = val.bodyItalic;
      this.assessmentsTextUnderline = val.bodyUnderline;
      this.assessmentsHide = CommonUtils.booleanToString(CommonUtils.isFalse(val.showTitle));
    }

    get assignmentsStyle() {
      return {
        titleFont: this.assignmentsFont,
        titleFontSize: this.assignmentsSize,
        titleColor: this.assignmentsColor,
        titleFillColor: this.assignmentsLabelFillColor,
        titleBold: this.assignmentsLabelBold,
        titleItalic: this.assignmentsLabelItalic,
        titleUnderline: this.assignmentsLabelUnderline,
        bodyFont: this.assignmentsTextFont,
        bodyFontSize: this.assignmentsTextSize,
        bodyColor: this.assignmentsTextColor,
        bodyFillColor: this.assignmentsTextFillColor,
        bodyBold: this.assignmentsTextBold,
        bodyItalic: this.assignmentsTextItalic,
        bodyUnderline: this.assignmentsTextUnderline,
        showTitle: CommonUtils.booleanToString(CommonUtils.isFalse(this.assignmentsHide))
      }
    }

    set assignmentsStyle(val: any) {
      this.assignmentsFont = val.titleFont;
      this.assignmentsSize = val.titleFontSize;
      this.assignmentsColor = val.titleColor;
      this.assignmentsLabelFillColor = val.titleFillColor;
      this.assignmentsLabelBold = val.titleBold;
      this.assignmentsLabelItalic = val.titleItalic;
      this.assignmentsLabelUnderline = val.titleUnderline;
      this.assignmentsTextFont = val.bodyFont;
      this.assignmentsTextSize = val.bodyFontSize;
      this.assignmentsTextColor = val.bodyColor;
      this.assignmentsTextFillColor = val.bodyFillColor;
      this.assignmentsTextBold = val.bodyBold;
      this.assignmentsTextItalic = val.bodyItalic;
      this.assignmentsTextUnderline = val.bodyUnderline;
      this.assignmentsHide = CommonUtils.booleanToString(CommonUtils.isFalse(val.showTitle));
    }

    get localLessonTitleStyle() {
      return {
        fontFamily: CommonUtils.getFontFamily(this.lessonTitleFont),
        fontSize: this.lessonTitleSize + 'pt',
        color: CommonUtils.getColor(this.lessonTitleColor),
        backgroundColor: CommonUtils.getColor(this.lessonTitleLabelFillColor),
        fontWeight: CommonUtils.isTrue(this.lessonTitleLabelBold) ? 'bold' : 'normal',
        fontStyle: CommonUtils.isTrue(this.lessonTitleLabelItalic) ? 'italic' : 'normal',
        textDecoration: CommonUtils.isTrue(this.lessonTitleLabelUnderline) ? 'underline' : 'none'
      }
    }

    get attachmentsTitleStyle() {
      return {
        fontFamily: CommonUtils.getFontFamily(this.attachmentsFont),
        fontSize: this.attachmentsSize + 'pt',
        color: CommonUtils.getColor(this.attachmentsColor),
        backgroundColor: CommonUtils.getColor(this.attachmentsLabelFillColor),
        fontWeight: CommonUtils.isTrue(this.attachmentsLabelBold) ? 'bold' : 'normal',
        fontStyle: CommonUtils.isTrue(this.attachmentsLabelItalic) ? 'italic' : 'normal',
        textDecoration: CommonUtils.isTrue(this.attachmentsLabelUnderline) ? 'underline' : 'none'
      }
    }

    get assessmentsTitleStyle() {
      return {
        fontFamily: CommonUtils.getFontFamily(this.assessmentsFont),
        fontSize: this.assessmentsSize + 'pt',
        color: CommonUtils.getColor(this.assessmentsColor),
        backgroundColor: CommonUtils.getColor(this.assessmentsLabelFillColor),
        fontWeight: CommonUtils.isTrue(this.assessmentsLabelBold) ? 'bold' : 'normal',
        fontStyle: CommonUtils.isTrue(this.assessmentsLabelItalic) ? 'italic' : 'normal',
        textDecoration: CommonUtils.isTrue(this.assessmentsLabelUnderline) ? 'underline' : 'none'
      }
    }

    get assessmentsBodyStyle() {
      return {
        fontFamily: CommonUtils.getFontFamily(this.assessmentsTextFont),
        fontSize: this.assessmentsTextSize + 'pt',
        color: CommonUtils.getColor(this.assessmentsTextColor),
        backgroundColor: CommonUtils.getColor(this.assessmentsTextFillColor),
        fontWeight: CommonUtils.isTrue(this.assessmentsTextBold) ? 'bold' : 'normal',
        fontStyle: CommonUtils.isTrue(this.assessmentsTextItalic) ? 'italic' : 'normal',
        textDecoration: CommonUtils.isTrue(this.assessmentsTextUnderline) ? 'underline' : 'none'
      }
    }

    get assignmentsTitleStyle() {
      return {
        fontFamily: CommonUtils.getFontFamily(this.assignmentsFont),
        fontSize: this.assignmentsSize + 'pt',
        color: CommonUtils.getColor(this.assignmentsColor),
        backgroundColor: CommonUtils.getColor(this.assignmentsLabelFillColor),
        fontWeight: CommonUtils.isTrue(this.assignmentsLabelBold) ? 'bold' : 'normal',
        fontStyle: CommonUtils.isTrue(this.assignmentsLabelItalic) ? 'italic' : 'normal',
        textDecoration: CommonUtils.isTrue(this.assignmentsLabelUnderline) ? 'underline' : 'none'
      }
    }

    get assignmentsBodyStyle() {
      return {
        fontFamily: CommonUtils.getFontFamily(this.assignmentsTextFont),
        fontSize: this.assignmentsTextSize + 'pt',
        color: CommonUtils.getColor(this.assignmentsTextColor),
        backgroundColor: CommonUtils.getColor(this.assignmentsTextFillColor),
        fontWeight: CommonUtils.isTrue(this.assignmentsTextBold) ? 'bold' : 'normal',
        fontStyle: CommonUtils.isTrue(this.assignmentsTextItalic) ? 'italic' : 'normal',
        textDecoration: CommonUtils.isTrue(this.assignmentsTextUnderline) ? 'underline' : 'none'
      }
    }

    get eventEnabled() {
      return this.getValue('eventEnabled', this.eventStyling.eventEnabled);
    }

    set eventEnabled(value: string) {
      this.setValue('eventEnabled', value);
    }

    get eventLabel() {
      return this.getValue('eventLabel', this.eventStyling.eventLabel);
    }

    set eventLabel(value: string) {
      this.setValue('eventLabel', value);
    }

    get eventLabelBold() {
      return this.getValue('eventLabelBold', this.eventStyling.eventLabelBold);
    }

    set eventLabelBold(value: string) {
      this.setValue('eventLabelBold', value);
    }

    get eventLabelColor() {
      return this.getValue('eventLabelColor', this.eventStyling.eventLabelColor);
    }

    set eventLabelColor(value: string) {
      this.setValue('eventLabelColor', value);
    }

    get eventLabelFillColor() {
      return this.getValue('eventLabelFillColor', this.eventStyling.eventLabelFillColor);
    }

    set eventLabelFillColor(value: string) {
      this.setValue('eventLabelFillColor', value);
    }

    get eventLabelFont() {
      return this.getValue('eventLabelFont', this.eventStyling.eventLabelFont);
    }

    set eventLabelFont(value: string) {
      this.setValue('eventLabelFont', value);
    }

    get eventLabelItalic() {
      return this.getValue('eventLabelItalic', this.eventStyling.eventLabelItalic);
    }

    set eventLabelItalic(value: string) {
      this.setValue('eventLabelItalic', value);
    }

    get eventLabelSize() {
      return this.getValue('eventLabelSize', this.eventStyling.eventLabelSize);
    }

    set eventLabelSize(value: string) {
      this.setValue('eventLabelSize', value);
    }

    get eventLabelUnderline() {
      return this.getValue('eventLabelUnderline', this.eventStyling.eventLabelUnderline);
    }

    set eventLabelUnderline(value: string) {
      this.setValue('eventLabelUnderline', value);
    }

    get eventTextBold() {
      return this.getValue('eventTextBold', this.eventStyling.eventTextBold);
    }

    set eventTextBold(value: string) {
      this.setValue('eventTextBold', value);
    }

    get eventTextColor() {
      return this.getValue('eventTextColor', this.eventStyling.eventTextColor);
    }

    set eventTextColor(value: string) {
      this.setValue('eventTextColor', value);
    }

    get eventTextDisplaySettingsId() {
      return this.getValue('eventTextDisplaySettingsId', this.eventStyling.eventTextDisplaySettingsId);
    }

    set eventTextDisplaySettingsId(value: string) {
      this.setValue('eventTextDisplaySettingsId', value);
    }

    get eventTextFillColor() {
      return this.getValue('eventTextFillColor', this.eventStyling.eventTextFillColor);
    }

    set eventTextFillColor(value: string) {
      this.setValue('eventTextFillColor', value);
    }

    get eventTextFont() {
      return this.getValue('eventTextFont', this.eventStyling.eventTextFont);
    }

    set eventTextFont(value: string) {
      this.setValue('eventTextFont', value);
    }

    get eventTextItalic() {
      return this.getValue('eventTextItalic', this.eventStyling.eventTextItalic);
    }

    set eventTextItalic(value: string) {
      this.setValue('eventTextItalic', value);
    }

    get eventTextSize() {
      return this.getValue('eventTextSize', this.eventStyling.eventTextSize);
    }

    set eventTextSize(value: string) {
      this.setValue('eventTextSize', value);
    }

    get eventTextUnderline() {
      return this.getValue('eventTextUnderline', this.eventStyling.eventTextUnderline);
    }

    set eventTextUnderline(value: string) {
      this.setValue('eventTextUnderline', value);
    }

    get eventsStyle() {
      return {
        titleFont: this.eventLabelFont,
        titleFontSize: this.eventLabelSize,
        titleColor: this.eventLabelColor,
        titleFillColor: this.eventLabelFillColor,
        titleBold: this.eventLabelBold,
        titleItalic: this.eventLabelItalic,
        titleUnderline: this.eventLabelUnderline,
        bodyFont: this.eventTextFont,
        bodyFontSize: this.eventTextSize,
        bodyColor: this.eventTextColor,
        bodyFillColor: this.eventTextFillColor,
        bodyBold: this.eventTextBold,
        bodyItalic: this.eventTextItalic,
        bodyUnderline: this.eventTextUnderline
      }
    }

    set eventsStyle(val: any) {
      this.eventLabelFont = val.titleFont;
      this.eventLabelSize = val.titleFontSize;
      this.eventLabelColor = val.titleColor;
      this.eventLabelFillColor = val.titleFillColor;
      this.eventLabelBold = val.titleBold;
      this.eventLabelItalic = val.titleItalic;
      this.eventLabelUnderline = val.titleUnderline;
      this.eventTextFont = val.bodyFont;
      this.eventTextSize = val.bodyFontSize;
      this.eventTextColor = val.bodyColor;
      this.eventTextFillColor = val.bodyFillColor;
      this.eventTextBold = val.bodyBold;
      this.eventTextItalic = val.bodyItalic;
      this.eventTextUnderline = val.bodyUnderline;
    }

    get eventsLabelStyle() {
      return {
        fontFamily: CommonUtils.getFontFamily(this.eventLabelFont),
        fontSize: this.eventLabelSize + 'pt',
        color: CommonUtils.getColor(this.eventLabelColor),
        backgroundColor: CommonUtils.getColor(this.eventLabelFillColor),
        fontWeight: CommonUtils.isTrue(this.eventLabelBold) ? 'bold' : 'normal',
        fontStyle: CommonUtils.isTrue(this.eventLabelItalic) ? 'italic' : 'normal',
        textDecoration: CommonUtils.isTrue(this.eventLabelUnderline) ? 'underline' : 'none',
        borderColor: CommonUtils.getColor(this.eventLabelFillColor)
      }
    }

    get eventsTextStyle() {
      return {
        fontFamily: CommonUtils.getFontFamily(this.eventTextFont),
        fontSize: this.eventTextSize + 'pt',
        color: CommonUtils.getColor(this.eventTextColor),
        backgroundColor: CommonUtils.getColor(this.eventTextFillColor),
        fontWeight: CommonUtils.isTrue(this.eventTextBold) ? 'bold' : 'normal',
        fontStyle: CommonUtils.isTrue(this.eventTextItalic) ? 'italic' : 'normal',
        textDecoration: CommonUtils.isTrue(this.eventTextUnderline) ? 'underline' : 'none'
      }
    }

    get eventsStickerLabelStyle() {
      return {
        fontFamily: CommonUtils.getFontFamily(this.eventStickerLabelFont),
        fontSize: this.eventStickerLabelSize + 'pt',
        color: CommonUtils.getColor(this.eventStickerLabelColor),
        backgroundColor: CommonUtils.getColor(this.eventStickerLabelFillColor),
        fontWeight: CommonUtils.isTrue(this.eventStickerLabelBold) ? 'bold' : 'normal',
        fontStyle: CommonUtils.isTrue(this.eventStickerLabelItalic) ? 'italic' : 'normal',
        textDecoration: CommonUtils.isTrue(this.eventStickerLabelUnderline) ? 'underline' : 'none',
        borderColor: CommonUtils.getColor(this.eventStickerLabelFillColor)
      }
    }

    get eventsStickerTextStyle() {
      return {
        fontFamily: CommonUtils.getFontFamily(this.eventStickerTextFont),
        fontSize: this.eventStickerTextSize + 'pt',
        color: CommonUtils.getColor(this.eventStickerTextColor),
        backgroundColor: CommonUtils.getColor(this.eventStickerTextFillColor),
        fontWeight: CommonUtils.isTrue(this.eventStickerTextBold) ? 'bold' : 'normal',
        fontStyle: CommonUtils.isTrue(this.eventStickerTextItalic) ? 'italic' : 'normal',
        textDecoration: CommonUtils.isTrue(this.eventStickerTextUnderline) ? 'underline' : 'none'
      }
    }

    get eventsStickerStyle() {
      return {
        titleFont: this.eventStickerLabelFont,
        titleFontSize: this.eventStickerLabelSize,
        titleColor: this.eventStickerLabelColor,
        titleFillColor: this.eventStickerLabelFillColor,
        titleBold: this.eventStickerLabelBold,
        titleItalic: this.eventStickerLabelItalic,
        titleUnderline: this.eventStickerLabelUnderline,
        bodyFont: this.eventStickerTextFont,
        bodyFontSize: this.eventStickerTextSize,
        bodyColor: this.eventStickerTextColor,
        bodyFillColor: this.eventStickerTextFillColor,
        bodyBold: this.eventStickerTextBold,
        bodyItalic: this.eventStickerTextItalic,
        bodyUnderline: this.eventStickerTextUnderline,
        stickerBackgroundStyle: this.stickerBackgroundStyle,
        stickerBackgroundColor: this.stickerBackgroundColor,
        stickerSize: this.stickerSize
      }
    }

    set eventsStickerStyle(val: any) {
      this.eventStickerLabelFont = val.titleFont;
      this.eventStickerLabelSize = val.titleFontSize;
      this.eventStickerLabelColor = val.titleColor;
      this.eventStickerLabelFillColor = val.titleFillColor;
      this.eventStickerLabelBold = val.titleBold;
      this.eventStickerLabelItalic = val.titleItalic;
      this.eventStickerLabelUnderline = val.titleUnderline;
      this.eventStickerTextFont = val.bodyFont;
      this.eventStickerTextSize = val.bodyFontSize;
      this.eventStickerTextColor = val.bodyColor;
      this.eventStickerTextFillColor = val.bodyFillColor;
      this.eventStickerTextBold = val.bodyBold;
      this.eventStickerTextItalic = val.bodyItalic;
      this.eventStickerTextUnderline = val.bodyUnderline;
      this.stickerBackgroundStyle = val.stickerBackgroundStyle;
      this.stickerBackgroundColor = val.stickerBackgroundColor;
      this.stickerSize = val.stickerSize;
    }

    get eventStickerLabelFont() {
      return this.getValue('eventStickerLabelFont', this.stickerStyling.eventStickerLabelFont);
    }

    set eventStickerLabelFont(value: string) {
      this.setValue('eventStickerLabelFont', value);
    }

    get eventStickerLabelSize() {
      return this.getValue('eventStickerLabelSize', this.stickerStyling.eventStickerLabelSize);
    }

    set eventStickerLabelSize(value: string) {
      this.setValue('eventStickerLabelSize', value);
    }

    get eventStickerLabelColor() {
      return this.getValue('eventStickerLabelColor', this.stickerStyling.eventStickerLabelColor);
    }

    set eventStickerLabelColor(value: string) {
      this.setValue('eventStickerLabelColor', value);
    }

    get eventStickerLabelFillColor() {
      return this.getValue('eventStickerLabelFillColor', this.stickerStyling.eventStickerLabelFillColor);
    }

    set eventStickerLabelFillColor(value: string) {
      this.setValue('eventStickerLabelFillColor', value);
    }

    get eventStickerLabelBold() {
      return this.getValue('eventStickerLabelBold', this.stickerStyling.eventStickerLabelBold);
    }

    set eventStickerLabelBold(value: string) {
      this.setValue('eventStickerLabelBold', value);
    }

    get eventStickerLabelItalic() {
      return this.getValue('eventStickerLabelItalic', this.stickerStyling.eventStickerLabelItalic);
    }

    set eventStickerLabelItalic(value: string) {
      this.setValue('eventStickerLabelItalic', value);
    }

    get eventStickerLabelUnderline() {
      return this.getValue('eventStickerLabelUnderline', this.stickerStyling.eventStickerLabelUnderline);
    }

    set eventStickerLabelUnderline(value: string) {
      this.setValue('eventStickerLabelUnderline', value);
    }

    get eventStickerTextFont() {
      return this.getValue('eventStickerTextFont', this.stickerStyling.eventStickerTextFont);
    }

    set eventStickerTextFont(value: string) {
      this.setValue('eventStickerTextFont', value);
    }

    get eventStickerTextSize() {
      return this.getValue('eventStickerTextSize', this.stickerStyling.eventStickerTextSize);
    }

    set eventStickerTextSize(value: string) {
      this.setValue('eventStickerTextSize', value);
    }

    get eventStickerTextColor() {
      return this.getValue('eventStickerTextColor', this.stickerStyling.eventStickerTextColor);
    }

    set eventStickerTextColor(value: string) {
      this.setValue('eventStickerTextColor', value);
    }

    get eventStickerTextFillColor() {
      return this.getValue('eventStickerTextFillColor', this.stickerStyling.eventStickerTextFillColor);
    }

    set eventStickerTextFillColor(value: string) {
      this.setValue('eventStickerTextFillColor', value);
    }

    get eventStickerTextBold() {
      return this.getValue('eventStickerTextBold', this.stickerStyling.eventStickerTextBold);
    }

    set eventStickerTextBold(value: string) {
      this.setValue('eventStickerTextBold', value);
    }

    get eventStickerTextItalic() {
      return this.getValue('eventStickerTextItalic', this.stickerStyling.eventStickerTextItalic);
    }

    set eventStickerTextItalic(value: string) {
      this.setValue('eventStickerTextItalic', value);
    }

    get eventStickerTextUnderline() {
      return this.getValue('eventStickerTextUnderline', this.stickerStyling.eventStickerTextUnderline);
    }

    set eventStickerTextUnderline(value: string) {
      this.setValue('eventStickerTextUnderline', value);
    }

    get showAllHolidays() {
      return this.getValue('showAllHolidays', this.stickerSettings.showAllHolidays);
    }

    set showAllHolidays(value: boolean) {
      this.setValue('showAllHolidays', value);
    }

    get showAllStudentBirthdays() {
      return this.getValue('showAllStudentBirthdays', this.stickerSettings.showAllStudentBirthdays);
    }

    set showAllStudentBirthdays(value: boolean) {
      this.setValue('showAllStudentBirthdays', value);
    }

    get dateDelimiter() {
      return this.getValue('dateDelimiter', this.dateStyling.dateDelimiter);
    }

    set dateDelimiter(value: any) {
      this.setValue('dateDelimiter', value);
    }

    get dateFormat() {
      return this.getValue('dateFormat', this.dateStyling.dateFormat);
    }

    set dateFormat(value: any) {
      this.setValue('dateFormat', value);
    }

    get nameOrder() {
      return this.getValue('nameOrder', this.otherSettings.nameOrder || 1);
    }

    set nameOrder(value: any) {
      this.setValue('nameOrder', value);
    }

    get isNameCommaSeparated() {
      return this.getValue('isNameCommaSeparated', this.otherSettings.isNameCommaSeparated);
    }

    set isNameCommaSeparated(value: any) {
      this.setValue('isNameCommaSeparated', value);
    }

    get sunShow() {
      return this.getValue('sunShow', this.dateStyling.sunShow);
    }

    set sunShow(value: any) {
      this.setValue('sunShow', value);
    }

    get monShow() {
      return this.getValue('monShow', this.dateStyling.monShow);
    }

    set monShow(value: any) {
      this.setValue('monShow', value);
    }

    get tueShow() {
      return this.getValue('tueShow', this.dateStyling.tueShow);
    }

    set tueShow(value: any) {
      this.setValue('tueShow', value);
    }

    get wedShow() {
      return this.getValue('wedShow', this.dateStyling.wedShow);
    }

    set wedShow(value: any) {
      this.setValue('wedShow', value);
    }

    get thuShow() {
      return this.getValue('thuShow', this.dateStyling.thuShow);
    }

    set thuShow(value: any) {
      this.setValue('thuShow', value);
    }

    get friShow() {
      return this.getValue('friShow', this.dateStyling.friShow);
    }

    set friShow(value: any) {
      this.setValue('friShow', value);
    }

    get satShow() {
      return this.getValue('satShow', this.dateStyling.satShow);
    }

    set satShow(value: any) {
      this.setValue('satShow', value);
    }

    get currentDateBackgroundColor() {
      return CommonUtils.getColor(this.getValue('currentDateBackgroundColor', this.dateStyling.currentDateBackgroundColor || 'inherit'));
    }

    set currentDateBackgroundColor(value: any) {
      this.setValue('currentDateBackgroundColor', value);
    }

    get currentAssessmentColor() {
      return CommonUtils.getColor(this.getValue('currentAssessmentColor', this.classworkStyling.currentAssessmentColor || 'purple'));
    }

    set currentAssessmentColor(value: any) {
      this.setValue('currentAssessmentColor', value);
    }

    get currentAssignmentColor() {
      return CommonUtils.getColor(this.getValue('currentAssignmentColor', this.classworkStyling.currentAssignmentColor || '#5DA500'));
    }

    set currentAssignmentColor(value: any) {
      this.setValue('currentAssignmentColor', value);
    }

    get currentDateTextColor() {
      return CommonUtils.getColor(this.getValue('currentDateTextColor', this.dateStyling.currentDateTextColor || 'inherit'));
    }

    set currentDateTextColor(value: any) {
      this.setValue('currentDateTextColor', value);
    }

    get showCount() {
      return CommonUtils.isTrue(this.getValue('showCount', this.dateStyling.showCount));
    }

    set showCount(value: any) {
      this.setValue('showCount', CommonUtils.booleanToString(value));
    }

    get dateFormatItems() {
      return dateFormatItems;
    }

    get dateDelimiterItems() {
      return dateDelimiterItems;
    }

    get nameOrderItems() {
      return nameOrderItems;
    }

    setValue(key: string, value: any): void {
      this.updatedSettings = ld.set(ld.cloneDeep(this.updatedSettings), key, value);
    }

    get showWeekDays() {
      const that: any = this;
      return this.weekDays.filter(d => CommonUtils.isTrue(that[d.value])).map(d => d.value);
    }

    set showWeekDays(days: Array<string>) {
      weekDays.forEach(d => this.setValue(d.value, 'N'));
      if (CommonUtils.isNotEmpty(days)) {
        days.forEach(d => this.setValue(d, 'Y'));
      }
    }

    get customFonts() {
      return this.getValue('customFonts', this.otherSettings.customFonts);
    }

    set customFonts(value: Array<any>) {
      this.setValue('customFonts', value);
    }

    get fontStyles() {
      return fontStyles;
    }

    get stickerBackgroundColor(): string {
      return this.getValue('stickerBackgroundColor', this.otherSettings.stickerBackgroundColor || 'inherit');
    }

    set stickerBackgroundColor(value: string) {
      this.setValue('stickerBackgroundColor', value);
    }

    get stickerBackgroundStyle(): string {
      return this.getValue('stickerBackgroundStyle', this.otherSettings.stickerBackgroundStyle);
    }

    set stickerBackgroundStyle(value: string) {
      this.setValue('stickerBackgroundStyle', value);
    }

    get stickerSize(): string {
      return this.getValue('stickerSize', this.otherSettings.stickerSize || '40')
    }

    set stickerSize(value: string) {
      this.setValue('stickerSize', value);
    }

    get iconStyles() {
      return iconStyles;
    }

    get constantFont() {
      return constantFont;
    }

    get notesIconColor(): string {
      return this.getValue('notesIconColor', this.otherSettings.notesIconColor || '#f29a25');
    }

    set notesIconColor(value: string) {
      this.setValue('notesIconColor', value);
    }

    get toDosIconColor(): string {
      return this.getValue('toDosIconColor', this.otherSettings.toDosIconColor || '#3e985c');
    }

    set toDosIconColor(value: string) {
      this.setValue('toDosIconColor', value);
    }

    get lowPriorityColor(): string {
      return this.getValue('lowPriorityColor', this.otherSettings.lowPriorityColor || '#FFA726');
    }

    set lowPriorityColor(value: string) {
      this.setValue('lowPriorityColor', value);
    }

    get mediumPriorityColor(): string {
      return this.getValue('mediumPriorityColor', this.otherSettings.mediumPriorityColor || '#F57C00');
    }

    set mediumPriorityColor(value: string) {
      this.setValue('mediumPriorityColor', value);
    }

    get highPriorityColor(): string {
      return this.getValue('highPriorityColor', this.otherSettings.highPriorityColor || '#BF360C');
    }

    set highPriorityColor(value: string) {
      this.setValue('highPriorityColor', value);
    }

    get reduceBrightnessOfPastDays() {
      return CommonUtils.get(this.getValue('reduceBrightnessOfPastDays', this.otherSettings.reduceBrightnessOfPastDays), false);
    }

    set reduceBrightnessOfPastDays(value: boolean) {
      this.setValue('reduceBrightnessOfPastDays', value);
    }

    get showSnackbarNotifications() {
      return CommonUtils.isTrue(this.getValue('showSnackbarNotifications', this.otherSettings.showSnackbarNotifications));
    }

    set showSnackbarNotifications(value: any) {
      this.setValue('showSnackbarNotifications', CommonUtils.booleanToString(value));
    }

    get timeFormat(): number {
      return CommonUtils.get(this.getValue('timeFormat', this.otherSettings.timeFormat), 0);
    }

    set timeFormat(value: number) {
      this.setValue('timeFormat', value);
    }

    get filteredDisplaySettingsTabs () {
      const that = this;
      return displaySettingsTabs.filter(d => {
        if ([
          'lessonSectionsGrouping',
          'lessonAutoSave',
          'lessonOrientation',
          'printLayoutHeader',
          'lessonHeadings',
          'lessonStatus',
          'lessonHover',
          'events',
          'dates',
          'classwork',
          'studentDisplay',
          'notesTodo'
        ].includes(d.id)) {
          return this.$currentUser.isTeacher;
        }
        return true;
      }).sort(function (a: any, b: any) {
        return that.compareStrings(that.$t(a.label), that.$t(b.label));
      })
    }

    get leftDrawerMenuType(): number {
      return this.getValue('leftDrawerMenuType', this.otherSettings.leftDrawerMenuType || 0);
    }

    set leftDrawerMenuType(value: number) {
      this.setValue('leftDrawerMenuType', value);
    }

    get globalLanguageItems():any[] {
      return this.languages;
    }

    get globalLanguage() {
      return this.getValue('globalLanguage', this.otherSettings.globalLanguage || 'en');
    }

    set globalLanguage(value: string) {
      this.setValue('globalLanguage', value);
    }

    compareStrings(a: any, b: any) {
      if (a === b) {
        return 0;
      } if (a === '') {
        return 1;
      } else if (b === '') {
        return -1;
      } else {
        return a < b ? -1 : 1;
      }
    }

    openCustomFontDialog() {
      this.onTabChange();
      if (this.$refs.uploadForm) {
        this.$refs.uploadForm.resetValidation();
      }
      this.customFontDialog = true;
    }

    saveFont() {
      if (this.$refs.uploadForm.validate()) {
        const url = this.addCustomFontType === 'F' ? URL.createObjectURL(this.fontFile as File) : this.fontURL;
        this.customFonts = this.customFonts.concat([{
          fontFamily: this.fontFamily,
          fontURL: url,
          fontFile: this.addCustomFontType === 'F' ? this.fontFile : undefined,
          teacherId: this.userInfo.userId
        }]);
        this.fontFamily = '';
        this.fontFile = undefined;
        this.fontURL = '';
        this.$refs.uploadForm.resetValidation();
        return false;
      }
      return true;
    }

    getValue(key: string, defaultValue: any) {
      if (Object.prototype.hasOwnProperty.call(this.updatedSettings, key)) {
        return this.updatedSettings[key];
      }
      return defaultValue;
    }

    getNumber(key: string, defaultValue: any) {
      if (Object.prototype.hasOwnProperty.call(this.updatedSettings, key)) {
        return +this.updatedSettings[key];
      }
      return +defaultValue;
    }

    onLogoSelected(e: Event) {
      const fileInput: any = e.target;
      this.logoFile = fileInput.files[0];
      if (this.logoFile) {
        this.center = URL.createObjectURL(this.logoFile);
      } else {
        this.logoFile = null;
      }
    }

    get getFontFamilyKey() {
      return CommonUtils.getFontFamilyKey;
    }

    selectLogo() {
      this.selectingLogo = true;
      window.addEventListener('focus', () => {
        this.selectingLogo = false;
      }, { once: true });
      this.$refs.fileInput.click();
    }

    initPrintHeadersForSave() {
      const newPrintHeaders = ld.cloneDeep(this.printHeaders);
      newPrintHeaders.forEach((h: any) => {
        h.selection = h.selection || '';
        h.customText = h.customText || '';
        h.showCustomText = h.showCustomText || false;
      })
      this.printHeaders = newPrintHeaders;
    }

    async save() {
      this.initPrintHeadersForSave();
      return this.saveDisplaySettings().then(resp => {
        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg24') as string);
        }

        return Promise.resolve(resp);
      });
    }

    async savePrintLayouts() {
      CommonUtils.showLoading();
      return Promise.resolve().then(async () => {
        if (this.logoFile) {
          return FileServices.uploadFile(this.logoFile).then((resp) => {
            this.center = resp.data.url;
            this.logoFile = null;
          });
        } else {
          return Promise.resolve();
        }
      }).then(() => {
        return this.save();
      }).then(() => {
        return this.reloadSettings();
      }).finally(CommonUtils.hideLoading);
    }

    async saveCustomFonts() {
      CommonUtils.showLoading();
      const customFonts = ld.cloneDeep(this.customFonts);
      return Promise.resolve().then(() => {
        const promises = customFonts.map(async f => {
          if (f.fontFile) {
            return FileServices.uploadFile(f.fontFile).then(resp => {
              f.fontURL = resp.data.url;
              f.fontFile = null;
              return Promise.resolve();
            }).catch(() => {
              return Promise.resolve();
            });
          } else {
            return Promise.resolve();
          }
        })
        return Promise.all(promises);
      }).then(() => {
        this.customFonts = customFonts;
        return this.save();
      }).then(() => {
        return this.reloadSettings();
      }).finally(CommonUtils.hideLoading);
    }

    setPlansFontStyling(params: any) {
      this.$store.commit('settings/setPlansFontStyling', params);
    }

    async saveGlobalSettings() {
      this.showGlobalFontOptions = false;
      this.globalFontOptionsSelected = [];
      const keys = Object.keys(this.updatedSettings);
      if (keys.includes('globalFontType') || keys.includes('globalFontSize')) {
        if (this.$currentUser.isTeacher) {
          this.showGlobalFontOptions = true;
          return this.$refs.confirm.confirm({
            title: this.$t('displaySettingsLabel'),
            text: this.$t('globalFontStyleConfirmMsg'),
            option1ButtonAlternativeText: this.$t('continueLabel')
          }).then(async (result: number) => {
            if (result === 1) {
              CommonUtils.showLoading();
              const gfsParams = { font: this.globalFontType || '2', size: this.globalFontSize || '10', ...this.globalFontParams };
              return this.updateGlobalFontStyle(gfsParams).then(() => {
                CommonUtils.hideLoading();
                this.setPlansFontStyling(gfsParams);
                this.updateColorTheme();
                return this.saveSettings();
              });
            } else {
              return Promise.resolve();
            }
          });
        } else {
          this.updateColorTheme();
          return this.saveSettings();
        }
      } else {
        this.updateColorTheme();
        return this.saveSettings();
      }
    }

    updateColorTheme() {
      if (this.updatedSettings.highContrastMode !== this.otherSettings.highContrastMode) {
        this.$vuetify.theme.themes.light = this.updatedSettings.highContrastMode ? contrastModeColors : lightModeColors;
      }
    }

    async saveSettings() {
      const languageChanged = this.updatedSettings && CommonUtils.hasValue(this.updatedSettings.globalLanguage);
      CommonUtils.showLoading();
      return this.save().then(() => {
        return this.reloadSettings();
      }).finally(() => {
        this.showGlobalFontOptions = false;
        CommonUtils.hideLoading();
        if (languageChanged) location.reload();
      });
    }

    removeFont(index: number) {
      this.customFonts = this.customFonts.filter((item, i) => i !== index);
    }

    removeLogo() {
      this.center = 'https://s3.amazonaws.com/planbook-assets/images/planbook-icon.png';
    }
}
